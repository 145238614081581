import { createContext } from 'react';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// import { selectInputInitialDef } from 'utils/selectInputInitialDef';
// Types
import { ContactBusinessTableContextType } from './types/ContactBusinessTableContextType';

export const ContactBusinessTableContext =
  createContext<ContactBusinessTableContextType>({    
    valueField: fieldInitialDef,
    filters: [],
    isFilterPanelOpen: false,
    isFetchingFilters: false,
    isDeletingFilters: false,
    handleFilterButton: () => { },
    handlePageNumberChange: () => { },
    handleContactClick: () => { },
    handleColumnClick: () => { },
    handleItemCheck: () => { },
    handlePageAmountChange: () => { },
    handleParentCheck: () => { },
    getContactCellValue: () => { },
    getBusinessCellValue: () => { },
    deleteFilter: () => { },
    addFilter: () => { },
    mode: 'contact',
    openModalList: false,
    setOpenModalList: () => { },
    modalTitle: '',
    setModalTitle: () => { },
    handleCloseModal: () => { },
  });
