import { createSlice, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
// Types
import { RootState } from '../../store';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { MessagesState } from './types/MessagesState';
import { Media } from './types/Media';
import { UploadMedia } from './types/UploadMedia';
import { NextMessages } from './types/NextMessages';
// Service
import messagesService from './messagesService';
import { IMessage } from '@trii/types/dist/Common/Messages';
// DB
import db from 'db/db';
import { Variable } from './types/Variable';

const initialState: MessagesState = {
  messages: null,
  status: {
    fetch: 'idle',
    send: 'idle',
    media: 'idle',
    nextMessages: 'idle',
    variable: 'idle',
  },
  media: null,
  variable: '',
  messagesOnError: [],
};

export const getMessages = createAsyncThunk(
  'conversation/getMessages',
  async (conversationId: string, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await messagesService.fetchMessages(
      jwtToken,
      URL_MESSAGES,
      conversationId,
      '0',
    );
    await db.updateMessages(response, conversationId);
    return response;
  }
);

export const getNextMessages = createAsyncThunk(
  'conversation/getNextMessages',
  async (data: NextMessages, { dispatch }) => {
    const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { conversationId, pos } = data;

    const response = await messagesService.fetchMessages(
      jwtToken,
      URL_MESSAGES,
      conversationId,
      pos,
    );

    return response;
  }
);

const convertDataUrlToBlob = (dataUrl: string) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
}

export const fetchMessage = createAsyncThunk(
  'conversation/sendMessage',
  async (message: IMessage, { dispatch }) => {
    const { jwtToken, URL_MESSAGES, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    if (message && message.audio) {
      const blob = convertDataUrlToBlob(message.audio.url);
      const formData = new FormData();
      formData.append('file', blob);
      const response = await messagesService.uploadMedia(
        jwtToken,
        URL_MEDIA,
        formData,
        '',
        '',
      );
      message.audio.url = response.url;
    }

    const response = await messagesService.sendMessages(
      jwtToken,
      URL_MESSAGES,
      message
    );

    return response;
  }
);

export const uploadMedia = createAsyncThunk(
  'conversation/uploadMedia',
  async (data: UploadMedia, { dispatch }) => {
    const { jwtToken, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { name, file, id } = data;
    const response = await messagesService.uploadMedia(
      jwtToken,
      URL_MEDIA,
      file,
      name,
      id,
    );

    return response;
  }
);

export const fetchVariables = createAsyncThunk<
  string,
  Variable
>(
  'contacts/fetchVariables', async (
    data,
    { dispatch }
  ) => {
  const { jwtToken, URL_MESSAGES } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const { variable, contactId, conversationId } = data;

  const response = await messagesService.fetchVariables(
    jwtToken,
    URL_MESSAGES,
    variable,
    contactId,
    conversationId
  );

  return response;
});


const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessagesOnError(state, action: PayloadAction<string>) {
      state.messagesOnError.push(action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(
        getMessages.fulfilled,
        (state, action: PayloadAction<IMessage[]>) => {
          state.messages = action.payload;
          state.status.fetch = 'succeeded';

          console.log('getMessages succeeded: ', action.payload);
        }
      )
      .addCase(getMessages.rejected, (state, action) => {
        state.status.fetch = 'rejected';
      })
      .addCase(fetchMessage.pending, (state) => {
        state.status.send = 'loading';
      })
      .addCase(
        fetchMessage.fulfilled,
        (state, action: PayloadAction<IMessage>) => {
          const message = action.payload;
          state.status.send = 'succeeded';
          state.messagesOnError = state.messagesOnError.filter((msg) =>
            msg !== message.id
          )
          console.log('fetchMessage succeeded: ', message);
        }
      )
      .addCase(fetchMessage.rejected, (state, action) => {
        state.status.send = 'rejected';
        console.log('fetchMessage rejected: ', action.meta.arg)
        setMessagesOnError(action.meta.arg.id)
      })
      .addCase(uploadMedia.pending, (state) => {
        state.status.media = 'loading';
      })
      .addCase(
        uploadMedia.fulfilled,
        (state, action: PayloadAction<Media>) => {
          const media = action.payload;
          state.status.media = 'succeeded';
          state.media = media;
        }
      )
      .addCase(uploadMedia.rejected, (state, action) => {
        state.status.media = 'rejected';
      })
      .addCase(getNextMessages.pending, (state) => {
        state.status.nextMessages = 'loading';
      })
      .addCase(
        getNextMessages.fulfilled,
        (state, action) => {
          const nextMessages = action.payload;
          state.status.nextMessages = 'succeeded';
          state.messages = nextMessages;
        }
      )
      .addCase(getNextMessages.rejected, (state, action) => {
        state.status.nextMessages = 'rejected';
      })
      .addCase(fetchVariables.pending, (state) => {
        state.status.variable = 'loading';
      })
      .addCase(
        fetchVariables.fulfilled,
        (state, action) => {
          const variable = action.payload;
          state.status.variable = 'succeeded';
          state.variable = variable;
        }
      )
      .addCase(fetchVariables.rejected, (state, action) => {
        state.status.variable = 'rejected';
      });
  },
});

// Actions
export const { setMessagesOnError } = messagesSlice.actions

const messagesState = (state: RootState) => state.Messages;
export const selectMessages = createSelector(messagesState, (state) => state.messages);
export const selectMessagesFetchStatus = createSelector(
  messagesState,
  (state) => state.status.fetch
);
export const selectUploadMediaStatus = createSelector(
  messagesState,
  (state) => state.status.media
);
export const selectUploadMedia = createSelector(
  messagesState,
  (state) => state.media
);
export const selectNextMessagesFetchStatus = createSelector(
  messagesState,
  (state) => state.status.nextMessages
)
export const selectVariableFetchStatus = createSelector(
  messagesState,
  (state) => state.status.variable
)
export const selectVariable = createSelector(
  messagesState,
  (state) => state.variable
)
export const selectSendMessageStatus = createSelector(
  messagesState,
  (state) => state.status.send
);
export const selectMessagesOnError = createSelector(
  messagesState,
  (state) => state.messagesOnError
)

export default messagesSlice.reducer;
