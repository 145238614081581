import { useContext, useEffect, useState } from "react"
// Redux
// Context
import { messagesContext } from "features/Views/MarketingView/context/MessagesProvider/MessagesProvider"
// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, TextField, Typography } from "@mui/material"

const Subject = () => {
  const {
    subject,
    setSubject,
  } = useContext(messagesContext)
  const { t } = useTranslation()
  const [text, setText] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
  }

  useEffect(() => {
    if (text) {
      const timeout = setTimeout(() => {
        setSubject(text)
      }, 1000)
      return () => clearTimeout(timeout)
    }
    setSubject('')
  }, [text])

  return (
    <Box
      height='3rem'
      width='100%'
      display='flex'
      alignItems='center'
      gap={1}
    >
      <Typography
        variant='body2'
        fontSize='.8rem'
        width='4rem'
        color={(theme) => theme.palette.text.primary}
      >
        {t('marketingView.message.email.subject')}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          variant='outlined'
          value={text}
          onChange={handleChange}
          size="small"
        />
      </Box>
    </Box>
  )
}

export default Subject