import { createContext, useState, ChangeEvent, useEffect, useRef } from 'react';
// Redux
import { PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
// Theme
import { useTheme } from '@mui/material';
// Slice
import { fetchVariables, getMessages, getNextMessages } from 'redux/features/messagesSlice/messagesSlice';
// Selector
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
// ID
import ObjectID from 'bson-objectid';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import useImagePreloader from 'hooks/useImagePreloader';
// Slice
import { fetchMessage, uploadMedia } from 'redux/features/messagesSlice/messagesSlice';
import { fetchContactsData, fetchFields, resetContacts } from 'redux/features/contactInfoSlice/contactInfoSlice';
// Types
import { MessagesContext } from './types/MessagesContext';
import { MessagesProviderProps } from './types/MessagesProviderProps';
import FileType from './types/FileType';
import { EmailDocument, IMessage } from '@trii/types/dist/Common/Messages';
import MediaFile from './types/MediaFile';
import {
  MessageAck,
  MessageDirection,
  MessageType,
} from '@trii/types/dist/Common/Messages';
import { NextMessages } from 'redux/features/messagesSlice/types/NextMessages';
import { Media } from 'redux/features/messagesSlice/types/Media';
import { Result } from 'redux/types/Gif';
import { DocumentType } from './types/DocumentType';
import { IContactAddress } from '@trii/types/dist/Contacts';
import { FieldsData } from 'redux/features/contactInfoSlice/types/FieldsData';
import { Variable } from 'redux/features/messagesSlice/types/Variable';
import { Pagination } from 'redux/features/contactInfoSlice/types/Pagination';
import { UserTrii } from '@trii/types/dist/Users';
// DB
import db from 'db/db'
// Images
import bgLight from './assets/images/bg-light.png';
import bgDark from './assets/images/bg-dark.png';

const REGEX_BASE64 = /^data:([a-z]+\/[a-z0-9-+.]+);base64/;

const MESSAGE_TEMPLATE = {
  id: '',
  spaceId: '',
  conversationId: '',
  timestamp: new Date(),
  author: null,
  from: null,
  to: '',
  mentions: null,
  direction: MessageDirection.OUT,
  ack: MessageAck.ACK_PENDING,
  forwarded: false,
  remoteDeleted: false,
  type: null,
  context: '',
  text: null,
  audio: null,
  contacts: [],
  messageReference: null,
  deleted: false,
  isLoaded: false,
  documents: null,
  updatedAt: new Date(),
  updatedBy: null
}

export const messagesContext = createContext<MessagesContext>({
  messageContainerRef: null,
  // Audio state
  uploadAudiosRejected: [],
  setUploadAudiosRejected: (rejected: string[]) => { },
  recordAudioMode: false,
  sendMessage: () => { },
  startRecordAudioMode: () => { },
  recordAudioStream: null,
  endRecordAudioMode: () => { },
  startFileSelectorMode: () => { },
  endFileSelectorMode: () => { },
  fileSelectorMode: null,
  handleFileRemove: (fileId: string) => { },
  selectedFile: null,
  handleFileSelect: (fileId: string) => { },
  handleFileUpload: (event: ChangeEvent<HTMLInputElement>) => { },
  files: [],
  handleFileMsgChange: (event: ChangeEvent<HTMLInputElement>, fileId: string) => { },
  handleUpload: (file: MediaFile[], messageId: string): Promise<MediaFile[] | DocumentType[]> => { return new Promise(() => { }) },
  filesToUpload: [],
  setFilesToUpload: (files: MediaFile[] | DocumentType[]) => { },
  messageLoading: [],
  setMessageLoading: (messageId: string[]) => { },
  sendImages: (files: MediaFile[], messageId: string) => { },
  handleUploadGif: (gif: Result) => { },
  getFileType: (type: string): FileType => { return 'image' },
  endDocumentSelectorMode: () => { },
  handleDocumentUpload: (event: ChangeEvent<HTMLInputElement>) => { },
  handleDocumentRemove: (documentId: string) => { },
  handleDocumentSelect: (documentId: string) => { },
  selectedDocument: null,
  documents: [],
  sendDocuments: (documents: DocumentType[], conversationId: string) => { },
  isSearching: false,
  setIsSearching: (isSearching: boolean) => { },
  message: null,
  setMessage: (message: IMessage | null) => { },
  isReplying: false,
  setIsReplying: (isReplying: boolean) => { },
  getNewMessages: (conversationId: string) => { return new Promise(() => { }) },
  getNextNewMessages: (data: NextMessages) => { return new Promise(() => { }) },
  footerSize: 'auto',
  setFooterSize: (size: string) => { },
  handleUploadFile: (e: ChangeEvent<HTMLInputElement>) => { return new Promise(() => { }) },
  from: '',
  setFrom: (value: string) => { },
  to: [],
  setTo: (user: IContactAddress[]) => { },
  isCc: false,
  setIsCc: (value: boolean) => { },
  cc: [],
  setCc: (user: IContactAddress[]) => { },
  isBcc: false,
  setIsBcc: (value: boolean) => { },
  bcc: [],
  setBcc: (user: IContactAddress[]) => { },
  subject: null,
  setSubject: (value: string) => { },
  body: null,
  setBody: (value: string) => { },
  attachments: null,
  setAttachments: (attachments: EmailDocument[]) => { },
  handleSearchContact: (data: Pagination) => { },
  sendEmail: (conversationId: string, newBody: string) => { },
  openEmailMode: false,
  setOpenEmailMode: (value: boolean) => { },
  resetEmailFields: () => { },
  openEmailModal: false,
  setOpenEmailModal: (value: boolean) => { },
  getContactFields: (data: FieldsData) => { },
  getVariableInfo: (data: Variable) => { return new Promise(() => { }) },
  editorState: '',
  setEditorState: (editorState: string) => { },
  // Background Image
  backgroundImage: '',
});

const MessagesProvider = ({ children }: MessagesProviderProps) => {
  // Theme
  const theme = useTheme();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [footerSize, setFooterSize] = useState<string>('auto')
  const [message, setMessage] = useState<IMessage | null>(null);
  const [messageLoading, setMessageLoading] = useState<string[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  // Chat state -> File selector
  const [fileSelectorMode, setFileSelectorMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState<MediaFile | null>(null);
  const [files, setFiles] = useState<MediaFile[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<MediaFile[] | DocumentType[]>([]);
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  // Chat state -> Audio
  const [uploadAudiosRejected, setUploadAudiosRejected] = useState<string[]>([]);
  const [recordAudioMode, setRecordAudioMode] = useState(false);
  const [recordAudioStream, setRecordAudioStream] = useState<null | MediaStream>(
    null
  );
  // Chat state -> Email
  const [openEmailMode, setOpenEmailMode] = useState<boolean>(false)
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<IContactAddress[]>([]);
  const [isCc, setIsCc] = useState<boolean>(false);
  const [cc, setCc] = useState<IContactAddress[]>([]);
  const [isBcc, setIsBcc] = useState<boolean>(false);
  const [bcc, setBcc] = useState<IContactAddress[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>(null);
  const [attachments, setAttachments] = useState<EmailDocument[]>([]);
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [editorState, setEditorState] = useState<string>('');
  // Messages Background Image
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const imageToPreload = theme.palette.mode === 'dark' ? bgDark : bgLight;
  const { imagesPreloaded } = useImagePreloader([imageToPreload]);

  const spaceInfo = useSelector(selectSpaceInfo);
  const userInfo: UserTrii = useSelector(selectUser);
  const user = {
    ...userInfo,
    id: userInfo.uid,
    name: userInfo.username,
    isActive: true,
  }
  const dispatch = useAppDispatch();

  // Chat -> General functions
  const getNewMessages = async (conversationId: string): Promise<IMessage[]> => {
    const result = await dispatch(getMessages(conversationId));
    return result.payload
  };

  const getNextNewMessages = async (data: NextMessages) => {
    const response = await dispatch(getNextMessages(data))
    return response.payload
  }

  const sendMessage = async (message: IMessage) => {
    await db.setMessage(message)
    setMessageLoading([...messageLoading, message.id]);
    const response = await dispatch(fetchMessage(message));
    setMessageLoading(messageLoading.filter((msg) => msg !== message.id));
    if (response.payload) {
      await db.setMessage(response.payload);
    }
  };

  const getFileType = (type: string): FileType => {
    if (type) {
      if (type.startsWith('text/') || type.startsWith('application/')) {
        return 'text';
      } else if (type.startsWith('image/')) {
        return 'image';
      } else if (type.startsWith('video/')) {
        return 'video';
      } else {
        return 'other';
      }
    }
  };

  const verifyAllLoaded = (files: MediaFile[]) => {
    return files.every((file) => {
      if (file) {
        const splitBase64 = file.url.split(',')[0]
        const isBase64 = REGEX_BASE64.test(splitBase64)
        return !isBase64;
      }
    })
  }

  // Chat -> Audio  functions
  const startRecordAudioMode = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      setRecordAudioStream(stream);
      setRecordAudioMode(true);
    });
  };

  const endRecordAudioMode = () => {
    setRecordAudioMode(false);
    if (recordAudioStream) {
      recordAudioStream.getTracks().forEach((track) => track.stop());
    }
  };

  // Chat -> File selector functions
  const startFileSelectorMode = () => {
    setFileSelectorMode(true);
  };
  const endFileSelectorMode = () => {
    setFiles([]);
    setSelectedFile(null);
    setFileSelectorMode(null);
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);

    if (newFiles.length === 0) {
      return;
    }

    const newFilesWithId = newFiles.map((file) => {
      const url = URL.createObjectURL(file);
      return {
        id: ObjectID().toString(),
        url,
        caption: '',
        mimeType: file.type,
        filename: file.name,
        file,
      };
    });

    setSelectedFile(newFilesWithId[0]);
    setFiles((prevFiles) => [...prevFiles, ...newFilesWithId]);
    setFileSelectorMode(true);
  };

  const handleFileRemove = (fileId: string) => {
    const newFiles = files.filter((file) => file.id !== fileId);

    if (newFiles.length === 0) {
      setFiles([]);
      setFileSelectorMode(null);
      setSelectedFile(null);

      return;
    }

    if (fileId === selectedFile?.id) {
      setSelectedFile(newFiles[0]);
    }

    setFiles(newFiles);
  };

  const handleFileMsgChange = (
    event: ChangeEvent<HTMLInputElement>,
    fileId: string
  ) => {
    const newFiles = files.map((file) => {
      if (file.id === fileId) {
        return Object.assign(file, { caption: event.target.value });
      }

      return file;
    });

    setFiles(newFiles);
  };

  const handleFileSelect = (fileId: string) => {
    const file = files.find((file) => file.id === fileId);

    if (file) {
      setSelectedFile(file);
    }
  };

  // Chat -> Email functions

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const documents = Array.from(event.target.files || []);

    if (documents.length === 0) {
      return;
    }

    const newDocuments = documents.map((file) => {
      const id = ObjectID().toString();
      setMessageLoading((prev) => [...prev, id]);
      const { type, name } = file;
      const data = {
        id,
        url: '',
        mimeType: type,
        filename: name,
        file,
      };
      setFilesToUpload((prevFiles) => [...prevFiles, data]);
      return data;
    });

    newDocuments.map(async (file) => {
      const formData = new FormData();
      formData.append('file', file.file);
      await dispatch(uploadMedia({
        file: formData,
        name: file.id,
        id: file.id,
      }))
    })
  };

  const handleSearchContact = async (data: Pagination) => {
    dispatch(resetContacts());
    await dispatch(fetchContactsData(data));
  }

  // Chat -> Document functions
  const endDocumentSelectorMode = () => {
    setDocuments([]);
    setSelectedDocument(null);
    setFileSelectorMode(false);
  };

  const handleDocumentUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const documents = Array.from(event.target.files || []);

    if (documents.length === 0) {
      return;
    }

    const newDocuments = documents.map((file) => {
      const id = ObjectID().toString();
      const { type, name } = file;
      return {
        id,
        url: '',
        mimeType: type,
        filename: name,
        file,
      };
    });

    setSelectedDocument(newDocuments[0]);
    setDocuments((prevFiles) => [...prevFiles, ...newDocuments]);
    setFileSelectorMode(true);
  };

  const handleDocumentRemove = (documentId: string) => {
    const newDocuments = documents.filter((file) => file.id !== documentId);

    if (newDocuments.length === 0) {
      endDocumentSelectorMode()
      return;
    }

    if (documentId === selectedFile?.id) {
      setSelectedDocument(newDocuments[0]);
    }

    setDocuments(newDocuments);
  };

  const handleDocumentSelect = (documentId: string) => {
    const document = documents.find((document) => document.id === documentId);

    if (document) {
      setSelectedDocument(document);
    }
  };

  const sendDocuments = async (documents: DocumentType[], conversationId: string) => {
    const messageId = ObjectID().toString();
    const newMessage = {
      ...MESSAGE_TEMPLATE,
      id: messageId,
      spaceId: spaceInfo.id,
      conversationId: conversationId,
      timestamp: new Date(),
      userId: user.uid,
      type: MessageType.CHAT,
      documents,
      updatedAt: new Date(),
      // updatedBy: user
    }
    db.setMessage(newMessage);
    const newDocuments = await handleUpload(documents, messageId) as DocumentType[];
    const verifyAllLoaded = newDocuments.every((document) => {
      if (document) {
        return document.url !== '';
      }
    })
    setMessageLoading(messageLoading.filter((msg) => msg !== messageId));
    if (newDocuments && verifyAllLoaded) {
      sendMessage({
        ...newMessage,
        documents: newDocuments,
        isLoaded: true,
      });
    }
  }

  // Chat -> Gif functions
  const handleUploadGif = async (gif: Result) => {
    const { media_formats } = gif;
    const url = media_formats.gif.url;
    const filename = url.split('/').pop() || '';
    const newGif = {
      id: ObjectID().toString(),
      url,
      caption: '',
      mimeType: 'image/gif',
      type: 'image',
      filename,
      previewUrl: media_formats.gifpreview.url,
    }
    setSelectedFile(newGif);
    setFiles((prevFiles) => [...prevFiles, newGif]);
    setFileSelectorMode(true);
  }

  // Chat -> Media functions
  const handleUpload = async (
    files: MediaFile[] | DocumentType[],
    messageId: string
  ): Promise<MediaFile[] | DocumentType[]> => {
    const newFiles = [...files] as MediaFile[] | DocumentType[];
    setFilesToUpload(files);
    files.map((file: MediaFile | DocumentType) => {
      if (file && file.id && !messageLoading.includes(messageId)) {
        setMessageLoading([...messageLoading, messageId]);
      }
    })
    for (const data of files) {
      if (data && data.file) {
        const { file, filename, id } = data;
        const formData = new FormData();
        formData.append('file', file, filename);
        const result = await dispatch(uploadMedia({
          file: formData,
          name: filename,
          id,
        })) as PayloadAction<Media>;
        if (result && result.payload) {
          const { url, id } = result.payload;
          newFiles.forEach((file: MediaFile | DocumentType) => {
            if (file.id === id) {
              file.url = url;
            }
          })
        }
      }
    };
    setMessageLoading(messageLoading.filter((msg) => msg !== messageId));
    return newFiles;
  };

  const sendImages = async (files: MediaFile[], conversationId: string) => {
    const messageId = ObjectID().toString();
    const images = files.filter((file) => {
      if (file) {
        const type = getFileType(file.mimeType);
        if (type === 'image') {
          return file
        }
      }
    })
    const videos = files.filter((file) => {
      if (file) {
        const type = getFileType(file.mimeType);
        if (type === 'video') {
          return {
            ...file,
            animated: false,
          }
        }
      }
    })
    const newMessage = {
      ...MESSAGE_TEMPLATE,
      id: messageId,
      spaceId: spaceInfo.id,
      conversationId: conversationId,
      timestamp: new Date(),
      userId: user.uid,
      type: MessageType.CHAT,
      images,
      videos,
      updatedAt: new Date(),
      // updatedBy: user
    }
    db.setMessage(newMessage);
    const newImages = (
      images.length > 0 &&
      await handleUpload(images, messageId) || []
    ) as MediaFile[];
    const newVideos = (
      videos.length > 0 &&
      await handleUpload(videos, messageId) || []
    ) as MediaFile[];
    setMessageLoading(messageLoading.filter((msg) => msg !== messageId));
    const isAllImagesCharged = images && verifyAllLoaded(images)
    const isAllVideosCharged = videos && verifyAllLoaded(videos)
    if ((newImages && newImages.length > 0) && newVideos && isAllImagesCharged && isAllVideosCharged) {
      sendMessage({
        ...newMessage,
        images: newImages,
        videos: newVideos,
        isLoaded: true,
      });
    } else if ((newImages && newImages.length > 0) && isAllImagesCharged) {
      sendMessage({
        ...newMessage,
        images: newImages,
        isLoaded: true,
      });
    } else if ((newVideos && newVideos.length > 0) && isAllVideosCharged) {
      sendMessage({
        ...newMessage,
        videos: newVideos,
        isLoaded: true,
      });
    }
  }

  // Chat -> Email functions
  const resetEmailFields = () => {
    setOpenEmailMode(false)
    setFooterSize('3rem')
    setTo([])
    setCc([])
    setIsCc(false)
    setBcc([])
    setIsBcc(false)
    setSubject('')
    setBody('')
    setAttachments([])
    setOpenEmailModal(false)
    setEditorState(null)
  }

  const sendEmail = async (conversationId: string, newBody: string) => {
    const messageId = ObjectID().toString();
    const emailsTo = to.map((user) => user.address).join(', ');
    const newMessage = {
      ...MESSAGE_TEMPLATE,
      id: messageId,
      spaceId: spaceInfo.id,
      conversationId: conversationId,
      timestamp: new Date(),
      type: MessageType.CHAT_EXTERNAL,
      userId: user.uid,
      from,
      to: emailsTo,
      email: {
        from,
        to,
        cc,
        bcc,
        subject,
        preHeader: '',
        bodyHtml: newBody,
        bodyText: newBody,
        attachments,
        timestamp: new Date(),
      },
      updatedAt: new Date(),
      // updatedBy: user
    }
    resetEmailFields()
    db.setMessage(newMessage);
    sendMessage(newMessage);
  }

  const getContactFields = async (data: FieldsData) => {
    await dispatch(fetchFields(data));
  }

  const getVariableInfo = async (data: Variable): Promise<string> => {
    const response = await dispatch(fetchVariables(data))
    return response.payload as string
  }

  useEffect(() => {
    if (imagesPreloaded) {
      setBackgroundImage(imageToPreload);
    }
  }, [imagesPreloaded, theme.palette.mode, imageToPreload])

  return (
    <messagesContext.Provider
      value={{
        messageContainerRef,
        // Audio state
        recordAudioMode,
        uploadAudiosRejected,
        setUploadAudiosRejected,
        sendMessage,
        startRecordAudioMode,
        recordAudioStream,
        startFileSelectorMode,
        endRecordAudioMode,
        endFileSelectorMode,
        fileSelectorMode,
        handleFileRemove,
        handleFileSelect,
        selectedFile,
        handleFileUpload,
        files,
        handleFileMsgChange,
        handleUpload,
        filesToUpload,
        setFilesToUpload,
        messageLoading,
        setMessageLoading,
        sendImages,
        handleUploadGif,
        getFileType,
        endDocumentSelectorMode,
        handleDocumentUpload,
        handleDocumentRemove,
        handleDocumentSelect,
        selectedDocument,
        documents,
        sendDocuments,
        isSearching,
        setIsSearching,
        message,
        setMessage,
        isReplying,
        setIsReplying,
        getNewMessages,
        getNextNewMessages,
        footerSize,
        setFooterSize,
        handleUploadFile,
        from,
        setFrom,
        to,
        setTo,
        isCc,
        setIsCc,
        cc,
        setCc,
        isBcc,
        setIsBcc,
        bcc,
        setBcc,
        subject,
        setSubject,
        body,
        setBody,
        attachments,
        setAttachments,
        handleSearchContact,
        sendEmail,
        openEmailMode,
        setOpenEmailMode,
        resetEmailFields,
        openEmailModal,
        setOpenEmailModal,
        getContactFields,
        getVariableInfo,
        editorState,
        setEditorState,
        // Background Image
        backgroundImage,
      }}
    >
      {children}
    </messagesContext.Provider>
  );
};

export default MessagesProvider;