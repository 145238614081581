import { Box, Button, Typography } from '@mui/material'
// Markdown
import { Markdown } from 'functions/Markdown'
import DocumentIcon from '../../../../../Messages/components/DocumentIcon/DocumentIcon';
import { useContext } from 'react';
import { messagesContext } from 'features/Views/MarketingView/context/MessagesProvider/MessagesProvider';

interface TextProps {
  text: string;
  buttons: any[];
}

const File = () => {
  const { getFileType, handleUpload } = useContext(messagesContext)

  // const handleTryAgain = () => {
  //   const filterDocument = documents.filter((document) => document.url === '');
  //   handleUpload(filterDocument, messageId)
  // }

  const handleDownload = (url: string) => {
    const link = document.createElement("a");
    const fileName = url.split("/").pop();
    link.download = fileName || "";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
     
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto 2rem',
                gap: '1em',
                placeItems: 'center',
                padding: '0.5em 1em',
                justifyContent: 'space-between',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
                margin: '3px 0',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
              // onClick={() => handleDownload(url)}
            >
              <Box
                sx={{
                  width: '3rem',
                  height: '3rem',
                }}
              >
                <DocumentIcon
                  documentType={"pdf"}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  textAlign: 'center',
                  marginTop: 1,
                  color: (theme) => theme.palette.text.secondary
                }}
                noWrap
              >
                document
              </Typography>
              {/* <Loader
                url={url}
                documentId={id}
                handleTryAgain={handleTryAgain}
                handleDownload={handleDownload}
              /> */}
            </Box>    
    </Box>
  )
}

export default File