import { createContext, useState } from "react";
import { MarketingType } from "./types/MarketingType";

export const MarketingContext = createContext<MarketingType>({
  openPanelCreateDifussion: "",
  setOpenPanelCreateDifussion: () => { },
  openModalPersField: false,
  setOpenModalPersField: () => { },
  openModalContactListDetails: false,
  setOpenModalContactListDetails: () => { },
  selectedFormat: "",
  setSelectedFormat: () => { },

});
const MarketingContextProvider = ({ children }) => {
    const [openPanelCreateDifussion, setOpenPanelCreateDifussion] = useState("");
    const [openModalPersField, setOpenModalPersField] = useState(false)
    const [openModalContactListDetails, setOpenModalContactListDetails] = useState(false)
    const [selectedFormat, setSelectedFormat] = useState("Texto")


return (
    <MarketingContext.Provider
      value={{
        openPanelCreateDifussion,
        setOpenPanelCreateDifussion,
        openModalPersField,
        setOpenModalPersField,
        selectedFormat,
        setSelectedFormat,
        openModalContactListDetails,
        setOpenModalContactListDetails
      }}
    >
      {children}
    </MarketingContext.Provider>
  );
};

export default MarketingContextProvider;