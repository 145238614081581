import { useState, useContext, useEffect } from 'react';
// Context
// Components/ui
import { Box } from '@mui/material';
// Components
import {
  Image,
} from './components';
// Types
import { IMessage, MessageDirection } from '@trii/types/dist/Common/Messages';
import { UserInfo } from '@trii/types/dist/Users';
// Moment
import moment from 'moment';
// Hooks
import { useUserInfo } from 'hooks/useUserInfo';
import { ChatType } from '@trii/types/dist/Conversations';
import dayjs from 'dayjs';
import { WhatsApp } from '@mui/icons-material';
import { style } from '../../../../../../../style';
import { useTranslation } from 'react-i18next';


const ImageType = () => {


  const [over, setOver] = useState<boolean>(false);

  const handleOver = () => {
    setOver(true);
  }
  const handleOut = () => {
    setOver(false);
  }

  const {t} = useTranslation()


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
      }}
      onMouseOver={handleOver}
      onMouseOut={handleOut}
    >
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0))",
          backgroundColor:
           //@ts-ignore
              (theme) => theme.palette.messageBox,
          float: "left",
          marginLeft: "5px",
          marginRight:  "20px" ,
          borderRadius: `5px 5px "5px 0px" `,
          border: "1px solid",
          borderColor:
        "transparent",
          maxWidth: "80%",
          height: "100%",
          padding: ".5rem",
          minWidth: "1rem",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            fontSize: "11px",
            color: (theme) => theme.palette.text.primary,
            justifyContent: "space-between",
            mt: 1

          }}
        >
          <Box display="flex" alignItems="center" mb={0.5}>
            <Box mr={0.5}>
              <WhatsApp sx={style.messageIconPreview}/></Box>
            <Box>{t("marketingView.createDiffusionView.message")}</Box>
          </Box>
         
        </Box>
       <Image  /> 
     
      </Box>
    </Box>
  );
}

export default ImageType;