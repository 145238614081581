// Redux
import { useSelector } from "react-redux";
// import { selectFilterPanelVisibility } from 'redux/features/contactsSlice/contactsSlice';
// Components/ui
import { Box } from "@mui/material";
import {
  ContactListPanel,
  CreateDifussionPanel,
  SidebarFilter,
} from "./components";
import { useContext } from "react";
import { MarketingContext } from "../../context/MarketingContext";

const AppBody = () => {
  // const isFilterPanelOpen = useSelector(selectFilterPanelVisibility);
  const { openPanelCreateDifussion } = useContext(MarketingContext);
  return (
    <Box
      display="flex"
      mt={1}
      height={"calc(100% - 10px)"}
      // maxHeight={'95%'}
      width="100%"
    >
      {openPanelCreateDifussion != "" ? (
        <CreateDifussionPanel />
      ) : (
        <>
          <SidebarFilter />
          <ContactListPanel />
        </>
      )}
    </Box>
  );
};

export default AppBody;
