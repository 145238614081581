import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import sessionReducer from './features/sessionSlice/sessionSlice';
import poolReducer from './features/poolSlice/poolSlice';
import themeReducer from './features/themeSlice';
import userReducer from './features/userSlice/userSlice';
import spaceSlice from './features/spaceSlice/spaceSlice';
import spacesSlice from './features/spacesSlice/spacesSlice';

import activitiesSlice from './features/activitiesSlice/activitiesSlice';
import messagesSlice from './features/messagesSlice/messagesSlice';
import labelReducer from './features/labelSlice/labelSlice';

import conversationsReducer from './features/conversationsSlice/conversationsSlice';
import endingReducer from './features/endingSlice/endingSlice';
import quickResponseSlice from './features/quickResponseSlice/quickResponseSlice';
import contactInfoSlice from './features/contactInfoSlice/contactInfoSlice';
import conversationReducer from './features/conversationSlice/conversationSlice';


const rootReducer = combineReducers({

  Spaces: spacesSlice,
  Space: spaceSlice,
  Session: sessionReducer,
  Pool: poolReducer,
  Theme: themeReducer,
  User: userReducer,
  Label: labelReducer,
  Activities: activitiesSlice,
  Messages: messagesSlice,
  Conversations: conversationsReducer,
  Ending: endingReducer,
  QuickResponse: quickResponseSlice,
  ContactInfo: contactInfoSlice,
  Conversation: conversationReducer,



});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
