import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { DashboardContaxtState } from "./types/DashboardContaxtState";

const DashboardContext = createContext<DashboardContaxtState>({
  // Card State
  cardConversationsCant: 0,
  setCardConversationsCant: () => { },
  cardInLineCant: 0,
  setInLineCant: () => { },
  cardInProgressCant: 0,
  setInProgressCant: () => { },
  cardExpiredCant: 0,
  setExpiredCant: () => { },
  cardContactsCant: 0,
  setContactsCant: () => { },
  cardFinishedCant: 0,
  setFinishedCant: () => { },
  cardOnlineAgentsCant: 0,
  setOnlineAgentsCant: () => { },
  cardAgentsGroupCant: 0,
  setAgentsGroupCant: () => { },
  cardAverageFirstAnsCant: '',
  setAverageFirstAnsCant: () => { },
  cardAverageFinishedCant: '',
  setAverageFinishedCant: () => { },
  // Modal State
  modalRegisterOpen: false,
  setModalRegisterOpen: () => { },
  modalRegisterTitle: '',
  setModalRegisterTitle: () => { },
  modalRegisterList: [],
  setModalRegisterList: () => { },
  // General Funcions
  obtainRegister: () => { },
});

const DashboardProvider = (props) => {
  // const [openModalRegister, setOpenModalRegister] = useState(false);

  const [cardConversationsCant, setCardConversationsCant] = useState(142)
  const [cardInLineCant, setInLineCant] = useState(1)
  const [cardInProgressCant, setInProgressCant] = useState(150)
  const [cardExpiredCant, setExpiredCant] = useState(9999)

  const [cardContactsCant, setContactsCant] = useState(29)
  const [cardFinishedCant, setFinishedCant] = useState(5)
  const [cardOnlineAgentsCant, setOnlineAgentsCant] = useState(1)
  const [cardAgentsGroupCant, setAgentsGroupCant] = useState(4)

  const [cardAverageFirstAnsCant, setAverageFirstAnsCant] = useState("0. 00:00:01")
  const [cardAverageFinishedCant, setAverageFinishedCant] = useState("1. 19:21:21")


  const [modalRegisterOpen, setModalRegisterOpen] = useState(false);
  const [modalRegisterTitle, setModalRegisterTitle] = useState("");
  const [modalRegisterList, setModalRegisterList] = useState([]);

  const obtainRegister = (type: string) => {
    if (type === "dashboard.cardFinished") {
      setModalRegisterList(
        [
          {
            channel: "TriiFinished",
            cant: "31/12/2023",
          },
        ],
      )
    }
    if (type === "dashboard.cardConversations") {
      setModalRegisterList(
        [
          {
            channel: "TriiConversations",
            cant: "31/12/2023",
          },
        ],
      )
    }
    if (type === "dashboard.cardNewsInline") {
      setModalRegisterList(
        [
          {
            channel: "TriiNewsInline",
            cant: "31/12/2023",
          },
        ],
      )
    }
    if (type === "dashboard.cardInProgressInAgents") {
      setModalRegisterList(
        [
          {
            channel: "TriicardInProgressInAgents",
            cant: "31/12/2023",
          },
        ],
      )
    }
    if (type === "dashboard.cardExpired") {
      setModalRegisterList(
        [
          {
            channel: "TriiExpired",
            cant: "31/12/2023",
          },
        ],
      )
    }
  };
  // React.useEffect(() => {
  //   console.log("", openModalRegister);
  // }, [openModalRegister]);


  return (
    <DashboardContext.Provider
      value={{
        cardConversationsCant,
        setCardConversationsCant,
        cardInLineCant,
        setInLineCant,
        cardInProgressCant,
        setInProgressCant,
        cardExpiredCant,
        setExpiredCant,
        cardContactsCant,
        setContactsCant,
        cardFinishedCant,
        setFinishedCant,
        cardOnlineAgentsCant,
        setOnlineAgentsCant,
        cardAgentsGroupCant,
        setAgentsGroupCant,
        cardAverageFirstAnsCant,
        setAverageFirstAnsCant,
        cardAverageFinishedCant,
        setAverageFinishedCant,
        obtainRegister,
        modalRegisterTitle,
        setModalRegisterTitle,
        modalRegisterList,
        setModalRegisterList,
        modalRegisterOpen,
        setModalRegisterOpen,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
DashboardProvider.propTypes = {
  children: PropTypes.node,
};

export { DashboardContext, DashboardProvider };
