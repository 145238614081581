import { useContext, useEffect } from "react"
// Redux
import { useSelector } from "react-redux"
// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material"
// Context
import { messagesContext } from "features/Views/MarketingView/context/MessagesProvider/MessagesProvider"
// Slice
import { selectUser } from "redux/features/userSlice/userSlice"

const FromBox = () => {
  const {
    from,
    setFrom,
  } = useContext(messagesContext)
  const user = useSelector(selectUser)
  const { t } = useTranslation()

  useEffect(() => {
    if (user) {
      setFrom(user.email)
    }
  }, [])

  return (
    <Box
      height='2rem'
      width='100%'
      display='flex'
      alignItems='center'
      gap={1}
    >
      <Typography
        variant='body2'
        fontSize='.8rem'
        width='4rem'
        color={(theme) => theme.palette.text.primary}
      >
        {t('marketingView.message.email.from')}
      </Typography>
      <FormControl size="small" fullWidth>
        <Select>
          <MenuItem>{from}</MenuItem>
        </Select>
      </FormControl>
      {/* <Typography
        variant='body2'
        color={(theme) => theme.palette.text.disabled}
        fontSize='.8rem'
        width='100%'
        sx={{
          backgroundColor: (theme) => (
            //@ts-ignore
            theme.palette.background.dropdownMenu
          ),
          borderRadius: '5px',
          paddingLeft: '.5rem',
        }}
      >
        {from}
      </Typography> */}
    </Box>
  )
}

export default FromBox