import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { MarketingContext } from "features/Views/MarketingView/context/MarketingContext";
import CloseIcon from "@mui/icons-material/Close";
import { style } from "../style";
import { utils, contactList, channelList } from "./utils/utils";
import { useTranslation } from "react-i18next";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs"
import Body from "./Body";
import { messagesContext } from "features/Views/MarketingView/context/MessagesProvider/MessagesProvider";
const EmailEdition = () => {
  const { setOpenPanelCreateDifussion } = useContext(MarketingContext);
  const closePanel = () => {
    setOpenPanelCreateDifussion("");
  };
  const {t} = useTranslation()
  const DATE_FORMAT = "DD/MM/YYYY HH:mm";
  const {
    openEmailModal,
    setOpenEmailModal,
    resetEmailFields,
  } = useContext(messagesContext)
  return (
    <Box sx={style.container}>
      {/* Header */}
      <Box>
        <Box sx={style.header}>
          <IconButton sx={{ postion: "absolute" }} onClick={closePanel}>
            <CloseIcon
              sx={{
                fontSize: 20,
                color: "text.primary",
              }}
            />
          </IconButton>
          <Box>
            <Typography sx={style.title}>
              {t("marketingView.createDiffusionView.emailEdition")}
            </Typography>
          </Box>
          <Box>
            {" "}
            <Button size="small" variant="contained">
              {t("global.save")}
            </Button>
          </Box>
        </Box>
      </Box>
      {/* Inputs y selects */}
      <Box sx={style.maxHeight}>
        <Box sx={{ mt: "35px" }}>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.state")}
            </Typography>
            <Box>
              <Chip label="tag" color="primary" />
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.title")}
            </Typography>
            <Box width={"250px"}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                fullWidth
                sx={style.textfield}
              ></TextField>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.contactList")}
            </Typography>
            <Box width={"250px"}>
              {" "}
              <FormControl size="small" fullWidth>
                {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
                <Select fullWidth>
                  {utils.map((value, i) => (
                    <MenuItem key={i} value={value.susName}>
                      {value.susName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={style.contactListCantContainer}>
              <Typography sx={style.contactListCant} variant="body2">
              {t("global.contacts")}: {contactList[0].cant}
              </Typography>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.subscription")}
            </Typography>
            <Box width={"250px"}>
              {" "}
              <FormControl size="small" fullWidth>
                {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
                <Select fullWidth>
                  {utils.map((value, i) => (
                    <MenuItem key={i} value={value.susName}>
                      {value.susName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.channel")}
            </Typography>
            <Box width={"250px"}>
              {" "}
              <FormControl size="small" fullWidth>
                {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
                <Select fullWidth>
                  {channelList.map((value, i) => (
                    <MenuItem key={i} value={value.name}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.shippingDate")}
            </Typography>
            <Box width={"250px"}>
              {" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  // disabled={!isEditing && showDetails}
                  format={DATE_FORMAT}
                  ampm={false}
                  slotProps={{
                    textField: () => ({
                      // label: t('marketingView.createDiffusionView.for'),
                      size: "small",
                      className: "smallInput",
                    }),
                  }}
                  // value={selectedStartDate}
                  // onChange={handleSelectStart}
                  sx={{ alignSelf: "baseline", flex: 1 }}
                  minDate={dayjs()}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}></Typography>
            <Box>
              {" "}
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={t("marketingView.createDiffusionView.linkTracking")}
              />
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}></Typography>
            <Box>
              {" "}
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={t(
                  "marketingView.createDiffusionView.instertWebBroserTag"
                )}
              />
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}></Typography>
            <Box>
              {" "}
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={t(
                  "marketingView.createDiffusionView.instertUnsubcribeTag"
                )}
              />
            </Box>
          </Box>
        </Box>
        {/* Message template y preview */}

        <Box sx={style.itemRedactEmailContainer}>
          {/* <Box sx={style.itemEmail}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.by")}:
            </Typography>
            <Box>
              <TextField></TextField>
            </Box>
          </Box>
          <Box sx={style.itemEmail}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.subject")}:
            </Typography>
            <Box>
              <TextField></TextField>
            </Box>
          </Box>
          <Box sx={style.itemEmail}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.message")}:
            </Typography>
            <Box>
              <TextField></TextField>
            </Box>
          </Box> */}
          <Body openModal={openEmailModal}></Body>


        </Box>
          <Box mt={"10px"}>
            {" "}
            <Button size="small" variant="contained">
              {t("marketingView.createDiffusionView.labelsForCustomFields")}
            </Button>
          </Box>
      </Box>
    </Box>
  );
};

export default EmailEdition;
