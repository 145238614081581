export const periodList = [
    {
        value: "day",
        label: "popperDays.day"
    },
    {
        value: "week",
        label: "popperDays.week"
    },
    {
        value: "month",
        label: "popperDays.month"
    },
    {
        value: "year",
        label: "popperDays.year"
    },
    {
        value: "last7days",
        label: "popperDays.last7Days"
    },
    {
        value: "last15days",
        label: "popperDays.last15Days"
    },
    {
        value: "last30days",
        label: "popperDays.last30Days"
    },
    {
        value: "last60days",
        label: "popperDays.last60Days"
    },
    {
        value: "custom",
        label: "popperDays.custom"
    }
]