// Redux
import { useSelector } from "react-redux";
// User Selector
import { selectUser } from "redux/features/userSlice/userSlice";
import moment from "moment";
import { Box } from "@mui/material";
// Components
import { ImageType, TextType } from "./components";
import { IMessage, MessageType } from "@trii/types/dist/Common/Messages";
import VideoType from "./components/VideoType";
import FileType from "./components/FileType";
import { useContext } from "react";
import { MarketingContext } from "features/Views/MarketingView/context/MarketingContext";

type MessagesProps = {
  message: IMessage;
};

// const MessagesTest = ({ message }: MessagesProps) => {
const MessagesTest = () => {
  const user = useSelector(selectUser);
  const userId = user?.uid;
  const { selectedFormat } = useContext(MarketingContext);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        {selectedFormat === "Texto" ? (
          <>
            <TextType />
          </>
        ) : selectedFormat === "Imagen" ? (
          <>
            <ImageType />
          </>
        ) : selectedFormat === "Video" ? (
          <>
            <VideoType />
          </>
        ) : selectedFormat === "Archivo" ? (
          <>
            <FileType />
          </>
        ) : (
          <></>
        )}
        {/* {message.type === MessageType.NOTE && (
          <NoteType text={message.text.body} />
        )}
        {message.type === MessageType.N2B && (
          <InfoType name={message.text.body} />
        )}
        {
          message.form && (
            <FormType form={message.form} />
          )
        } */}
      </Box>
    </Box>
  );
};

export default MessagesTest;
