import { Suspense } from 'react';
import App from 'App';
// Types
import RootProps from 'types/RootProps.js';
// Translation
import './i18n.js';
import DataProvider from './context/contextApi';
import i18next from 'i18next';
import StyleProvider from './style/styleProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import store from './redux/features/store';

const Root = (props: RootProps) => {
  return (
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18next}>
        <DataProvider>
          <StyleProvider>
            <Provider store={store}>
              <Router>
                <App rootProps={props} />
              </Router>
            </Provider>
          </StyleProvider>
        </DataProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default Root;
