import { useContext } from 'react';
import { StyleContext } from 'style/styleProvider';
import PropTypes from 'prop-types';
// Tyopes
import  Style  from 'style/types/Style';

const HeadCellTitle = ({ title }) => {
  const { color } = useContext<Style>(StyleContext);

  return (
    <>
      <span style={{ color: color.slate[400], marginRight: 6 }}>|</span>
      {title}
    </>
  );
};

HeadCellTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeadCellTitle;
