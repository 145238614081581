export const SharedList = [
  {
    channel: "Correo",
    cant: 0,
  },
  {
    channel: "Facebook",
    cant: 0,
  },
  {
    channel: "Linkedin",
    cant: 0,
  },
  {
    channel: "Whatsapp",
    cant: 0,
  },
  {
    channel: "Twitter",
    cant: 0,
  },
];