import { useState, useEffect, useContext } from 'react';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Components/ui
import {  LoadingAppliedFilters } from './components';
import { Fade, Theme, Box } from '@mui/material';
import AdvancedFilter from './components/AdvancedFilter';
// import { CollapseAnimation } from 'components';

const SidebarFilter = () => {
  // const { isDeletingFilters } = useContext(ContactBusinessTableContext);
  const [openPanel, setOpenPanel] = useState(false);
  const [showContent, setShowContent] = useState(false);

  // useEffect(() => {
  //   isFilterPanelOpen
  //     ? setOpenPanel(true)
  //     : setTimeout(() => {
  //         setOpenPanel(false);
  //       }, 50);

  //   !showContent
  //     ? setTimeout(() => {
  //         setShowContent(isFilterPanelOpen);
  //       }, 85)
  //     : setShowContent(isFilterPanelOpen);
  // }, [isFilterPanelOpen, showContent]);

  return (
    // <CollapseAnimation openPanel={openPanel}>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        height={'100%'}
        sx={{
          //@ts-ignore
          backgroundColor: (theme: Theme) => theme.palette.background.dropdownMenu,
        }}
        boxShadow={'0px 1px 2px rgba(0,0,0,0.2)'}
        borderRadius={'2px'}
        mr={1}
        p={2}
        width={"15%"}
      >
        {/* <Fade in={showContent}> */}
          <Box width={'100%'}>

            <AdvancedFilter />
            {/* {isDeletingFilters ? <LoadingAppliedFilters /> : <TagList />} */}
          </Box>
        {/* </Fade> */}
      </Box>
    // </CollapseAnimation>
  );
};

export default SidebarFilter;
