/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { TransferConversationData } from './types/TransferConversationData';
import { ModifyConversationLabelData } from './types/ModifyConversationLabelData';
import { FinalizeConversationData } from './types/FinalizeConversationData';
import type { FetchConversationsFilter } from './types/FetchConversationsFilter';
import { OpenInFooter } from './types/OpenInFooter';
import { CreateConversation } from 'features/Views/MarketingView/context/ConversationsProvider/types/CreateConversation';
import { Chat } from './types/Chat';
import { UpdateChat } from './types/UpdateChat';
import { Pin } from './types/Pin';

const fetchConversations = async (
  jwt: string,
  URL: string,
  filter: FetchConversationsFilter
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/Conversations?inbox=${filter}`,
    requestConfig
  );

  return response.data;
};
const pinConversation = async (jwt: string, URL: string, data: Pin) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJson = JSON.stringify(data);
  const response = await axios.put(
    `${URL}/Conversations/action/pinned`,
    dataJson,
    requestConfig
  );

  return response.data;
};
const inConversation = async (jwt: string, URL: string, conversationId: string) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const data = { conversationId };
  const dataJson = JSON.stringify(data);
  const response = await axios.put(
    `${URL}/Conversations/action/in`,
    dataJson,
    requestConfig
  );

  return response.data;
};
const outConversation = async (jwt: string, URL: string, conversationId: string) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const data = { conversationId };
  const dataJson = JSON.stringify(data);
  const response = await axios.put(
    `${URL}/Conversations/action/out`,
    dataJson,
    requestConfig
  );

  return response.data;
};
const transferConversation = async (
  jwt: string,
  URL: string,
  data: TransferConversationData
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJson = JSON.stringify(data);
  const response = await axios.put(
    `${URL}/Conversations/action/transfer`,
    dataJson,
    requestConfig
  );

  return response.data;
};
const modifyLabel = async (
  jwt: string,
  URL: string,
  data: ModifyConversationLabelData
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJson = JSON.stringify(data);
  const response = await axios.put(
    `${URL}/Conversations/action/labels`,
    dataJson,
    requestConfig
  );

  return response.data;
};
const finalizeConversation = async (
  jwt: string,
  URL: string,
  data: FinalizeConversationData
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJson = JSON.stringify(data);
  const response = await axios.put(
    `${URL}/Conversations/action/finalize`,
    dataJson,
    requestConfig
  );

  return response.data;
};

const fetchUsers = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(`${URL}/users`, requestConfig);

  return response.data;
}

const fetchGroups = async (jwt: string, URL: string) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(`${URL}/groups`, requestConfig);

  return response.data;
}

const fetchChannels = async (
  jwt: string,
  URL: string,
  channel: string
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/channels/${channel}`,
    requestConfig
  );

  return response.data;
}

const fetchOpenInFooter = async (
  jwt: string,
  URL: string,
  data: OpenInFooter
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const dataJson = JSON.stringify(data);
  const response = await axios.put(
    `${URL}/Conversations/action/openInFooter`,
    dataJson,
    requestConfig
  );

  return response.data;
}

const createConversation = async (
  jwt: string,
  URL: string,
  data: CreateConversation
) => {
  try {

    const requestConfig = getRequestConfig.contentTypeJson(jwt);
    const dataJson = JSON.stringify(data);
    const response = await axios.post(
      `${URL}/Conversations`,
      dataJson,
      requestConfig
    );

    return response.data;
  } catch (error) {
    return error.response;
  }
};

const fetchNewChat = async (
  jwt: string,
  URL: string,
  data: Chat,
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const dataJson = JSON.stringify(data);
  const response = await axios.post(
    `${URL}/chats`,
    dataJson,
    requestConfig
  );

  return response.data;
}

const fetchChats = async (
  jwt: string,
  URL: string,
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/chats`,
    requestConfig
  );

  return response.data;
}

const fetchUpdateChat = async (
  jwt: string,
  URL: string,
  data: UpdateChat,
) => {
  const requestConfig = getRequestConfig.contentTypeJson(jwt);
  const { chatId } = data;
  delete data.chatId;
  const response = await axios.put(
    `${URL}/chats/${chatId}`,
    data,
    requestConfig
  );

  return response.data;
}

const fetchConversationsOfContact = async (
  jwt: string,
  URL: string,
  contactId: string,
  status: string,
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/Conversations?contactId=${contactId}&inbox=${status}`,
    requestConfig
  );

  return response.data;
}

export default {
  fetchConversations,
  pinConversation,
  inConversation,
  outConversation,
  transferConversation,
  modifyLabel,
  finalizeConversation,
  fetchUsers,
  fetchGroups,
  fetchChannels,
  fetchOpenInFooter,
  createConversation,
  fetchNewChat,
  fetchChats,
  fetchUpdateChat,
  fetchConversationsOfContact,
};
