import React from 'react';
// Components from Material UI
import { Box, Divider, Typography } from '@mui/material';
// Translations
import { useTranslation } from 'react-i18next';
// Components
import { Groups, Schedules } from './General';
import {
  Email,
  SMS,
  SIP,
  WebChat,
  WhatsApp,
  Facebook,
  Instagram,
  PBX,
  Forms,
  MercadoLibre,
  GoogleBusiness,
} from './ComunicationChannels';
import { WebHook } from './Integrations';
import {
  ConversationEndings,
  AutomaticResponses,
  Flows,
  Labels,
} from './Conversations';

const SystemMVC = () => {
  const { t } = useTranslation();

  return (
    <Box className={'marketing-fadeinup'}>
      <Box
        style={{
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('settingsView.general')}
        </Typography>
      </Box>
      <Box display={'flex'} margin={2} sx={{ flexWrap: 'wrap', gap: '1rem 3rem' }}>       
        <Schedules />
      </Box>  
      <Divider sx={{ marginTop: '30px' }} />

    </Box>
  );
};

export default SystemMVC;
