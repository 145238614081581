import * as React from "react";
// Translations
import { useTranslation } from "react-i18next";
// Components
import {
  Box,
  Chip,
  ClickAwayListener,
  Fade,
  FormControl,
  MenuItem,
  Popover,
  Popper,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// Icons
import { CalendarMonth } from "@mui/icons-material";
// Dayjs
import dayjs, { Dayjs } from "dayjs";
// Utils
import { periodList } from "./utils/periodList";

const Period = () => {
  const [open, setOpen] = React.useState(false);
  const [grupo, setGrupo] = React.useState("day");
  const [value, setValue] = React.useState<Dayjs>(dayjs);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [title, setTitle] = React.useState("");
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  }

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setGrupo(event.target.value);
    if (value === "custom") return;
    handleClose();
  };


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  React.useEffect(() => {
    const newTitle = periodList.find((item) => item.value === grupo)?.label;
    setTitle(newTitle);
  }, [grupo]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Chip
        label={`: ${t(title)}`}
        icon={<CalendarMonth />}
        variant="outlined"
        onClick={handleClick}
        sx={{ zIndex: 0, cursor: "pointer" }}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disablePortal={false}
        sx={{
          mt: 1,
        }}
      >
        <Box
          sx={{
            p: 2,
            pt: 1,
            boxShadow: "rgb(0 0 0 / 25%) 0px 1px 6px 0px !important",
            width: 200,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {/* title */}
            <Typography sx={{ color: "text.primary" }}>
              {t("global.period")}:
            </Typography>
            {/* select */}
            <FormControl size="small" fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={grupo}
                label="Periodo"
                onChange={handleChange}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                {
                  periodList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}
                    >
                      {t(item.label)}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            {grupo === "custom" ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  slotProps={{
                    textField: () => ({
                      label: t("global.from"),
                      size: "small",
                      className: "smallInput",
                    }),
                  }}
                  sx={{
                    marginTop: "5px",
                  }}
                />
              </LocalizationProvider>
            ) : null}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default Period;