import { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import {
  GraphicFinalsCard,
  GroupUserCard,
  MembersCard,
  NumberCard,
  Period,
  RegisterModal,
  SimpleListCard,
  TimesCard,
} from "./components";
import { useTranslation } from "react-i18next";
import {
  DashboardContext,
  DashboardProvider,
} from "./context/DashboardContext";
import { DataContext } from "context/contextApi";
import { Flex } from "@tremor/react";
import GrapichDestiny from "./components/GraphicDestiny/GrapichDestiny";
import PerformanceCard from "./components/PerformanceCard";
import { OpeningRateList } from "./utils/Lists/OpeningRateList";
import { ClicksRateList } from "./utils/Lists/ClicksRateList";
import { SharedList } from "./utils/Lists/SharedList";
import { DesuscriptionList } from "./utils/Lists/DesuscriptionList";
import { useOpenCallPanelWidth } from "features/Layout/MainLayout/utils/useOpenCallPanelWidth";
// import { getSpaces } from "redux/features/spacesSlice/spacesSlice";
// import { useAppDispatch } from "hooks/useAppDispatch";

const Dashboard = () => {
  const {
    cardConversationsCant,
    cardInLineCant,
    cardInProgressCant,
    cardExpiredCant,
  } = useContext(DashboardContext);
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useContext(DataContext);

  // useEffect(() => {
  //   dispatch(getSpaces());
  // }, [dispatch]);
  const { boxDashboardWidth } = useOpenCallPanelWidth();

  return (
    <DashboardProvider>
      <Box p={2} sx={!isMobile ? { minWidth: "1024px" } : undefined}>
      <Box  >
        <Period />
        {/* cards */}
        <Box className="card-container">
          {/* Rendimiento */}
          <Box
            style={{
              display: "flex",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {t("dashboard.performance")}
            </Typography>
          </Box>
          <Box
            className="marketing-row"
            sx={{ justifyContent: "space-between", pt: "10px" }}
          >
            <Flex
              className="overflow-x-auto pb-1.5"
              style={
                {
                  // maxWidth: "calc(100vw - 165px)",
                  // paddingRight: "5px",
                  // paddingLeft: "5px",
                }
              }
            >
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="channels"
                  title={t("dashboard.openingRate")}
                  number={cardInLineCant}
                  list={OpeningRateList}

                />
              </Box>
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="tagList"
                  title={t("dashboard.clicksRate")}
                  number={cardInLineCant}
                  list={ClicksRateList}

                />
              </Box>
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="tagList"
                  title={t("dashboard.shared")}
                  number={cardInLineCant}
                  list={SharedList}
                />
              </Box>
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="tagList"
                  title={t("dashboard.unsubscriptions")}
                  number={cardInLineCant}
                  list={DesuscriptionList}

                />
              </Box>
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="tagList"
                  title={t("dashboard.complaints")}
                  number={cardInLineCant}
                  list={""}
                />
              </Box>
            </Flex>
          </Box>
          {/* Panel de reportes */}
          <Box
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {t("dashboard.reportPanel")}
            </Typography>
          </Box>
          <Box
            className="marketing-row"
            sx={{ justifyContent: "center", pt: "10px" }}
          >
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.sentCampaigns")}
                number={cardConversationsCant}
                type="dashboard.cardConversations"
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.shipmentsMade")}
                number={cardInLineCant}
                type="dashboard.cardNewsInline"
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalOpenings")}
                number={cardInProgressCant}
                type="dashboard.cardInProgressInAgents"
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.expired")}
                number={cardExpiredCant}
                type="dashboard.cardExpired"
              />
            </Box>
          </Box>

          <Box
            className="marketing-row"
            sx={{ justifyContent: "center", pt: "10px" }}
          >
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalShares")}
                number={cardConversationsCant}
                type="dashboard.cardConversations"
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalUsubscriptions")}
                number={cardConversationsCant}
                type="dashboard.cardConversations"
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalComplaints")}
                number={cardConversationsCant}
                type="dashboard.cardConversations"
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalRebounds")}
                number={cardConversationsCant}
                type="dashboard.cardConversations"
              />
            </Box>
          </Box>
          {/* Destinatarios y entregas */}
          <Box
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {t("dashboard.recipientsAndDeliveries")}
            </Typography>
          </Box>
          <Box
            className="marketing-row"
            sx={{ justifyContent: "center", pt: "10px" }}
          >
            <Box className="marketing-col-6">
              <SimpleListCard id="channels" title={t("dashboard.recipients")} />
            </Box>
            <Box className="marketing-col-6">
              <GrapichDestiny />
            </Box>
          </Box>
          {/* Actividad en el tiempo */}
          <Box
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {t("dashboard.activityOverTime")}
            </Typography>
          </Box>
          <Box
            className="marketing-row"
            sx={{ justifyContent: "center", pt: "10px" }}
          >
            <Box className="marketing-col-12">
              <GraphicFinalsCard />
            </Box>
          </Box>
        </Box>
        </Box>
        <RegisterModal></RegisterModal>
      </Box>
    </DashboardProvider>
  );
};

export default Dashboard;
