import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { MarketingContext } from "features/Views/MarketingView/context/MarketingContext";
import CloseIcon from "@mui/icons-material/Close";
import { style } from "../style";
import { novedadesList, contactList, flowList, channelList } from "./utils/utils";
import MessageTabs from "./components/MessageTabs";
import { useTranslation } from "react-i18next";
import { Preview } from "./components";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import ModalContactListDetails from "./components/Modals/ModalContactListDetails";

const WhatsappEdition = () => {
  const { setOpenPanelCreateDifussion, setOpenModalContactListDetails } = useContext(MarketingContext);
  const closePanel = () => {
    setOpenPanelCreateDifussion("");
  };
  const { t } = useTranslation();

  const DATE_FORMAT = "DD/MM/YYYY HH:mm";
  const handleOpenModalContactListDetails = () => {
    setOpenModalContactListDetails(true)

  }
  return (
    <Box sx={style.container}>
      {/* Header */}
      <Box>
        <Box sx={style.header}>
          <IconButton sx={{ postion: "absolute" }} onClick={closePanel}>
            <CloseIcon
              sx={{
                fontSize: 20,
                color: "text.primary",
              }}
            />
          </IconButton>
          <Box>
            <Typography sx={style.title}>
              {t("marketingView.createDiffusionView.wspEdition")}
            </Typography>
          </Box>
          <Box>
            <Button size="small" variant="contained">
              {t("global.save")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={style.maxHeight}>
        {/* Inputs y selects */}
        <Box sx={{ mt: "35px" }}>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.state")}
            </Typography>
            <Box>
              <Chip label="tag" color="primary" />
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.title")}
            </Typography>
            <Box width={"250px"}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                fullWidth
                sx={style.textfield}
                placeholder={t("marketingView.createDiffusionView.enterATitle")}
              ></TextField>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.contactList")}
            </Typography>
            <Box width={"250px"}>
              {" "}
              <FormControl size="small" fullWidth>
                {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
                <Select fullWidth>
                  {contactList.map((value, i) => (
                    <MenuItem key={i} value={value.name}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={style.contactListCantContainer}>
              <Typography sx={style.contactListCant} variant="body2">
              {t("global.contacts")}: {contactList[0].cant}
              </Typography>
              <Typography sx={style.contactListCantDetails} variant="caption" onClick={handleOpenModalContactListDetails}>
                ({t("marketingView.createDiffusionView.seeDetails")})
              </Typography>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.subscription")}
            </Typography>
            <Box width={"250px"}>
              {" "}
              <FormControl size="small" fullWidth>
                {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
                <Select fullWidth>
                  {novedadesList.map((value, i) => (
                    <MenuItem key={i} value={value.name}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.channel")}
            </Typography>
            <Box width={"250px"}>
              {" "}
              <FormControl size="small" fullWidth>
                {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
                <Select fullWidth>
                  {channelList.map((value, i) => (
                    <MenuItem key={i} value={value.name}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>Flow</Typography>
            <Box width={"250px"}>
              {" "}
              <FormControl size="small" fullWidth>
                {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
                <Select fullWidth>
                  {flowList.map((value, i) => (
                    <MenuItem key={i} value={value.name}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={style.item}>
            <Typography sx={style.label}>
              {t("marketingView.createDiffusionView.shippingDate")}
            </Typography>
            <Box width={"250px"}>
              {" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  // disabled={!isEditing && showDetails}
                  format={DATE_FORMAT}
                  ampm={false}
                  slotProps={{
                    textField: () => ({
                      // label: t('marketingView.createDiffusionView.for'),
                      size: "small",
                      className: "smallInput",
                    }),
                  }}
                  // value={selectedStartDate}
                  // onChange={handleSelectStart}
                  sx={{ alignSelf: "baseline", flex: 1 }}
                  minDate={dayjs()}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        {/* Message template y preview */}
        <Box sx={style.messagePreviewContainer}>
          <MessageTabs></MessageTabs>
          <Preview></Preview>
        </Box>
      </Box>
      <ModalContactListDetails></ModalContactListDetails>
    </Box>
  );
};

export default WhatsappEdition;
