export const style = {
  container: {
    height: "100%",
    overflow: "hidden",
  },
  header: {
    postion: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  item: {
    display: "flex",
    alignItems: "center",
    // height: 55,
    width: "100%",
    // backgroundColor: "background.panel",
    padding: "0 10px",
    mb: "15px",
    borderRadius: 1,
  },
  item2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // height: 55,
    width: "100%",
    // backgroundColor: "background.panel",
    padding: "0 10px",
    borderRadius: 1,
  },
  label: {
    letterSpacing: -0.5,
    fontWeight: "600",
    width: "15%",
    color: "text.primary",
  },
  textRedact: {
    letterSpacing: -0.5,
    fontWeight: "600",
    width: "165px",
    color: "text.primary",
    marginRight: "15px",
  },
  title: {
    letterSpacing: -0.5,
    fontWeight: "600",
    width: "15%",
    color: "text.primary",
    textWrap: "nowrap",
    fontSize: "1.5rem",
  },
  itemRedactEmailContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 10px",
    marginTop: "20px",
    border: "1px solid lightgray",
    borderRadius: "5px",
    marginRight: "16px",
    paddingTop: "15px",
    backgroundColor: "background.panel"
  },
  itemEmail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: 1,
  },
  textfield: {
    // borderRadius: '13px',
    // '& .MuiOutlinedInput-root': {
    //   '& fieldset': {
    //     borderColor: 'transparent',
    //   },
    //   '&:hover fieldset': {
    //     borderColor: 'transparent',
    //   },
    //   '&.Mui-focused fieldset': {
    //     borderColor: 'transparent',
    //   },
    // },
    // border: '1px solid lightgray',
    marginBottom: "0px",
  },
  maxHeight: {
    overflow: "auto",
    height: "95%",
    padding: "10px 0px",
  },
  previewContainer: {
    width: "45%",
    height: "100%",
  },
  messagePreviewContainer: {
    display: "flex",
    justifyContent: "space-between",
    // height: 55,
    width: "100%",
    // backgroundColor: "background.panel",
    padding: "0 10px",
    paddingLeft: 0
  },
  contactListCant: {
    marginRight: "10px",
  },
  contactListCantDetails: {
    color: "initial",
    textDecoration: "none",
    cursor: "pointer",
    transition: "color 0.3s ease, text-decoration 0.3s ease",
    "&:hover": {
      color: (theme) => theme.palette.text.colorMain,
      textDecoration: "underline", 
    },
  },
  contactListCantContainer: {
    ml: "10px",
    display: "flex",
    alignItems: "center",
  },
  messageIconPreview: {
    userSelect: "none",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fill: "currentcolor",
    flexShrink: 0,
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: "1.25rem",
    color: "rgba(0, 0, 0, 0.58)",
  }
};
