import React from "react"
import {
  Card,
  List,
  ListItem,
  Icon,
  Text,
  Bold,
  Flex,
  Title,
  Metric,
  Divider,
} from "@tremor/react";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";

// const MockList = [];
const MockList = [
  {
    icon: "img/channels/19.png",
    channel: "Trii",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.soluc",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
];

const PerformanceCard = (item) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      }}
    >
      <Box
        className="truncate text-center"
        style={{ color: theme.palette.text.primary }}
      >
        <Title style={{ color: theme.palette.text.primary }}>
          {item.title}
        </Title>
        <Metric className="text-center cursor-pointer text-2xl">
          {item.number}
        </Metric>
      </Box>
      <Divider></Divider>
      <List
        className="mt-4  overflow-y-auto pr-2  min-h-382 position-relative"
        style={{ minHeight: "150px", maxHeight: "150px" }}
      >
        {MockList.length > 0 && (item.list == "" || item.list == null) ? (
          <>
            {MockList.map((transaction) => (
              <ListItem key={transaction.channel} className=" py-1">
                <Flex className="justify-start  truncate">
                  {/* <img src={`${__webpack_public_path__}${transaction.icon}`} alt="" /> */}
                  <div className="truncate">
                    <Text className="truncate">
                      <Bold>{transaction.channel}</Bold>
                    </Text>
                  </div>
                </Flex>
                <Text>{transaction.cant}</Text>
              </ListItem>
            ))}
          </>
        ) : (
          <>
            {item.list?.map((listItem) => (
              <ListItem key={listItem.channel} className=" py-1">
                <Flex className="justify-start  truncate">
                  {/* <img src={`${__webpack_public_path__}${transaction.icon}`} alt="" /> */}
                  {/* <Icon icon={FacebookIcon} size="sm" /> */}
                  <div className="truncate">
                    <Text className="truncate">
                      <Bold>{listItem.channel}</Bold>
                    </Text>
                  </div>
                </Flex>
                <Text>{listItem.cant}</Text>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </Card>
  );
};

export default PerformanceCard