import Dexie from 'dexie';
// Types
import { IConversation } from '@trii/types/dist/Conversations';
import { IMessage, MessageAck } from '@trii/types/dist/Common/Messages';
class ChatDatabase extends Dexie {
  conversations: Dexie.Table<IConversation, string>;
  chats: Dexie.Table<IConversation, string>;
  messages: Dexie.Table<IMessage, string>;

  constructor() {
    super('ChatDatabase');

    // La línea this.version(1).stores(...) especifica la versión de la base de datos y define las estructuras de las tablas.
    // En este caso, se establece la versión de la base de datos en 1 y se define una única tabla llamada "conversations".
    // El argumento de stores(...) es un objeto que contiene las definiciones de las tablas. Cada propiedad del objeto representa una tabla, donde la clave es el nombre de la tabla y el valor es una cadena que especifica la clave primaria de la tabla.
    // En este caso, la clave primaria de la tabla "conversations" se establece en el campo "id".
    this.version(3.1).stores({
      conversations: 'id, spaceId',
      chats: 'id, spaceId',
      messages: 'id, conversationId',
    });

    // La línea this.conversations = this.table('conversations') crea una referencia a la tabla "conversations" en la base de datos y la asigna a la propiedad conversations de la instancia de la clase.
    // Esto permite acceder a la tabla y realizar operaciones en ella, como agregar, actualizar, eliminar y consultar registros.
    this.conversations = this.table('conversations');
    this.chats = this.table('chats');
    this.messages = this.table('messages');
  }

  // Conversations Section

  async updateConversations(conversations: IConversation[]) {
    const existingConversations = await this.conversations.toArray();
    const existingConversationIds = existingConversations.map(
      (conversation) => conversation.id
    );
    const receivedConversationIds = conversations.map(
      (conversation) => conversation.id
    );

    const conversationsToAdd = conversations.filter(
      (conversation) => !existingConversationIds.includes(conversation.id)
    );
    const conversationsToDelete = existingConversations.filter(
      (conversation) => !receivedConversationIds.includes(conversation.id)
    );
    const conversationsToUpdate = conversations.filter((conversation) =>
      existingConversationIds.includes(conversation.id)
    );

    await this.transaction('rw', this.conversations, async () => {
      if (conversationsToAdd.length > 0) {
        await this.conversations.bulkAdd(conversationsToAdd);
      }

      if (conversationsToDelete.length > 0) {
        const conversationIdsToDelete = conversationsToDelete.map(
          (conversation) => conversation.id
        );
        await this.conversations.where('id').anyOf(conversationIdsToDelete).delete();
      }

      if (conversationsToUpdate.length > 0) {
        for (const conversation of conversationsToUpdate) {
          await this.conversations.put(conversation);
        }
      }
    });
  }

  async setConversation(conversation: IConversation) {
    const conversations = await this.conversations
      .where('id')
      .equals(conversation.id)
      .toArray();
    if (conversations.length > 0) {
      await this.updateConversation(conversation);
    } else {
      await this.conversations.add(conversation);
    }
  }

  async updateConversation(conversation: IConversation) {
    const id = conversation.id;
    await this.conversations
      .where('id')
      .equals(id)
      .modify({ ...conversation });
  }
  async deleteConversation(conversationId: string) {
    const conversations = this.conversations.where('id').equals(conversationId);
    const data = await conversations.toArray();
    if (data.length > 0) {
      await conversations.delete();
    }
  }
  // End conversations Section

  // Internal Chats Section
  async updateChats(chats: IConversation[]) {
    const existingChats = await this.chats.toArray();
    const existingChatsIds = existingChats.map((chat) => chat.id);
    const receivedChatsIds = chats.map((chat) => chat.id);

    const chatsToAdd = chats.filter((chat) => !existingChatsIds.includes(chat.id));
    const chatsToDelete = existingChats.filter(
      (chat) => !receivedChatsIds.includes(chat.id)
    );
    const chatsToUpdate = chats.filter((chat) => existingChatsIds.includes(chat.id));

    await this.transaction('rw', this.chats, async () => {
      if (chatsToAdd.length > 0) {
        await this.chats.bulkAdd(chatsToAdd);
      }

      if (chatsToDelete.length > 0) {
        const chatsIdsToDelete = chatsToDelete.map((chat) => chat.id);
        await this.chats.where('id').anyOf(chatsIdsToDelete).delete();
      }

      if (chatsToUpdate.length > 0) {
        for (const chat of chatsToUpdate) {
          await this.chats.put(chat);
        }
      }
    });
  }

  async updateChat(chat: IConversation) {
    const id = chat.id;
    await this.chats
      .where('id')
      .equals(id)
      .modify({ ...chat });
  }
  async deleteChat(chatId: string) {
    await this.chats.where('id').equals(chatId).delete();
  }
  // End Internal Chats Section

  // Messages Section
  async updateMessages(messages: IMessage[], conversationId: string) {
    const existingMessages = await this.messages
      .where({
        conversationId,
      })
      .toArray();
    const existingMessageIds = existingMessages.map((message) => message.id);
    const receivedMessageIds = messages.map((message) => {
      if (message.conversationId === conversationId) {
        return message.id;
      }
    });

    const messagesToAdd = messages.filter(
      (message) =>
        message.conversationId === conversationId &&
        !existingMessageIds.includes(message.id)
    );
    const messagesToDelete = existingMessages.filter(
      (message) =>
        message.conversationId === conversationId &&
        message.ack !== MessageAck.ACK_PENDING &&
        !receivedMessageIds.includes(message.id)
    );
    const messagesToUpdate = messages.filter(
      (message) =>
        message.conversationId === conversationId &&
        existingMessageIds.includes(message.id)
    );

    await this.transaction('rw', this.messages, async () => {
      if (messagesToAdd.length > 0) {
        await this.messages.bulkAdd(messagesToAdd);
      }

      if (messagesToDelete.length > 0) {
        const messageIdsToDelete = messagesToDelete.map((message) => message.id);
        await this.messages.where('id').anyOf(messageIdsToDelete).delete();
      }

      if (messagesToUpdate) {
        for (const message of messagesToUpdate) {
          await this.messages.put(message);
        }
      }
    });
  }

  async updateMessage(message: IMessage) {
    const id = message.id;
    await this.messages
      .where('id')
      .equals(id)
      .modify({ ...message });
  }

  async setMessage(message: IMessage) {
    const messages = await this.messages.where('id').equals(message.id).toArray();
    if (messages.length > 0) {
      await this.updateMessage(message);
    } else {
      await this.messages.add(message);
    }
  }

  async setMessages(messages: IMessage[]) {
    const existingMessages = await this.messages.toArray();
    const existingMessagesId = existingMessages.map((message) => message.id);
    const newMessages = messages.filter(
      (message) => !existingMessagesId.includes(message.id)
    );
    await this.messages.bulkAdd(newMessages);
  }

  async getAllMessages(conversationId: string) {
    return await this.messages
      .where('conversationId')
      .equals(conversationId)
      .toArray();
  }
  // End Messages Section
}

const db = new ChatDatabase();

export default db;
