import React, { useContext } from "react";
import { Card, Metric, Title } from "@tremor/react";
import { DashboardContext } from "features/Views/Dashboard/context/DashboardContext";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";

const NumberCard = (item) => {
  const {
    setModalRegisterTitle,
    setModalRegisterOpen,
    obtainRegister
  } = useContext(DashboardContext)
  const handleOpenModalRegister = () => {
    if (item.type) {
      obtainRegister(item.type)
      setModalRegisterTitle(item.type);
      setModalRegisterOpen(true);
    }
  }
  const theme = useTheme();
  const cardStyle = {
    //@ts-ignore
    backgroundColor: theme.palette.background.panel,
    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
  };
  return (
    
    <Card className="p-2" style={cardStyle}>
      <Box className="truncate" style={{ color: theme.palette.text.primary}}>
        <Title className="text-center">{item.title}</Title>
        <Metric className="text-center cursor-pointer text-2xl" onClick={handleOpenModalRegister}>{item.number}</Metric>
      </Box>
    </Card>
  );
};

export default NumberCard;
