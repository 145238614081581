import React, { createContext, useEffect, useState } from 'react';
// Redux
import { initRequestData } from 'redux/functions/initRequestData';
// Socket.IO
import { io, Socket } from 'socket.io-client';
// Types
import SocketContextType from './types/SocketContextType';
import SocketConnectionStatus from './types/SocketConnectionStatus';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';

export const socketContext = createContext<SocketContextType>({
  socketConnection: null,
  connectionStatus: 'offline',
  subscribeEvent: () => { },
  unsubscribeEvent: () => { },
  emitEvent: () => { },
});

interface Props {
  children: React.ReactNode;
}

export const SocketProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [token, setToken] = useState<string>('');
  const [socketConnection, setSocketConnection] = useState<Socket | null>(null);
  const [connectionStatus, setConnectionStatus] =
    useState<SocketConnectionStatus>('offline');

  const getToken = async () => {
    const { jwtToken } = (
      await dispatch(initRequestData())
    ).payload as InitRequestDataReturn;
    return jwtToken;
  };

  // Event functions
  const subscribeEvent = (eventName: string, callback: (...args: any[]) => void) => {
    if (socketConnection) {
      socketConnection.on(eventName, callback);
    }
  };
  const unsubscribeEvent = (eventName: string) => {
    if (socketConnection) {
      socketConnection.off(eventName);
    }
  };
  const emitEvent = (eventName: string, ...args: any[]) => {
    if (socketConnection) {
      socketConnection.emit(eventName, ...args);
    }
  };

  useEffect(() => {
    if (token) return;

    const initializeToken = async () => {
      const jwtToken = await getToken();
      setToken(jwtToken);
    };

    initializeToken();
  }, []);

  useEffect(() => {
    if (socketConnection) return;

    const socket = io('https://ws.trii.app', {
      auth: {
        token: '123456',
      },
    });

    setSocketConnection(socket);

    socket.on('connect', () => {
      console.log('Conversations Connected to Socket.IO server');
      setConnectionStatus('online');
    });
    socket.on('disconnect', () => {
      console.log('Conversations Disconnected from Socket.IO server');
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  return (
    <socketContext.Provider
      value={{
        subscribeEvent,
        unsubscribeEvent,
        emitEvent,
        socketConnection,
        connectionStatus,
      }}
    >
      {children}
    </socketContext.Provider>
  );
};

export default SocketProvider;
