import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Popper,
  Slide,
  Typography,
} from "@mui/material";
import { MarketingContext } from "features/Views/MarketingView/context/MarketingContext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const ModalPersField = () => {
  const { t } = useTranslation();
  const {setOpenModalPersField, openModalPersField} = useContext(MarketingContext)
  const handleClose = () => {
    setOpenModalPersField(false)
  }
  return (
    <Box
      sx={{
        display: openModalPersField ? "flex" : "none",
        justifyContent: "center",
        alignItems: "start",
        marginTop: "1rem",
        background: "transparent",
        "& .MuiBackdrop-root": {
          background: "transparent",
        },
      }}
    >

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // maxWidth: width,
            maxHeight: "40rem",
            minHeight: "10rem",
            height: "max-content",
            borderRadius: 2,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "3rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1em",
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography
              variant="h6"
              color={(theme) => theme.palette.text.primary}
            >
              agregar etiqueta
            </Typography>
            <IconButton
              onClick={handleClose}
            >
              <Close
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </IconButton>
          </Box>
        </Box>
    </Box>
  );
};

export default ModalPersField;
