import { Box, Card, CardActionArea, Typography } from '@mui/material'
import React from 'react'

const Button = ({children}) => {

    return (
        <Box
            component={Card}
            borderRadius={2}
            width={"fit-content"}
            // marginRight="15px"
            data-aos={'fade-up'}
            minWidth={"200px"}
            sx={{backgroundColor: (theme) => theme.palette.background.default}}
        >
            <CardActionArea>
                {children}
            </CardActionArea>
        </Box>
    )
}

export default Button