import { useContext } from 'react';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useSelector } from 'react-redux';
// import {
//   selectContacts,
//   selectBusiness,
//   selectContactsFetchStatus,
//   getFetchContactFieldsStatus,
//   selectBusinessFetchStatus,
// } from 'redux/features/contactsSlice/contactsSlice';
// Components/ui
import { TableBody } from '@mui/material';
import { Contact } from './components';

const Body = () => {
  // const { mode } = useContext(ContactBusinessTableContext);
  // const columnsFetchStatus = useSelector(getFetchContactFieldsStatus);
  // const itemsFetchStatus = useSelector(
  //   mode === 'contact' ? selectContactsFetchStatus : selectBusinessFetchStatus
  // );

  // const items =
  //   mode === 'contact' ? useSelector(selectContacts) : useSelector(selectBusiness);

  const Items = <Contact/>;

  return (
    <TableBody>
      {
        Items}

      {/* {columnsFetchStatus === 'loading' || itemsFetchStatus === 'loading' ? (
        <ContactsSkeleton />
      ) : (
        Items
      )} */}
    </TableBody>
  );
};

export default Body;
