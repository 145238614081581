import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSession } from '../features/sessionSlice/sessionSlice';
import {
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';
// Types
import InitRequestDataReturn from '../types/InitRequestDataReturn';
import { RootState } from '../rootReducer';

interface CognitoUserSession {
  idToken?: CognitoIdToken;
  refreshToken?: CognitoRefreshToken;
  accessToken?: CognitoAccessToken;
  clockDrift?: number;
}

export const initRequestData = createAsyncThunk<
  InitRequestDataReturn,
  void,
  { state: RootState }
>('session/initRequest', async (_, { dispatch, getState }) => {
  const sessionRefresh: CognitoUserSession = (await dispatch(setSession())).payload;
  const { Space } = getState();
  const jwtToken = sessionRefresh.idToken.getJwtToken();
  const URL_USERS = Space.URL.USERS;
  const URL_USER = Space.URL.USER;
  const URL_CONVERSATIONS = Space.URL.CONVERSATIONS;
  const URL_CONTACTS = Space.URL.CONTACTS;
  const URL_MEDIA = Space.URL.MEDIA;
  const URL_MESSAGES = Space.URL.MESSAGES;
  const URL_CALENDAR = Space.URL.CALENDAR;

  return {
    jwtToken,
    URL,
    URL_USERS,
    URL_CONVERSATIONS,
    URL_CONTACTS,
    URL_MEDIA,
    URL_USER,
    URL_MESSAGES,
    URL_CALENDAR,
  };
});
