import { useState, useContext } from 'react';
// Context
// Redux
import { useSelector } from 'react-redux';
// import { selectPagination } from 'redux/features/contactsSlice/contactsSlice';
// Components/ui
import { HeadCellTitle } from './components';
// import { ArrowIcon } from './components';
import { TableCell, IconButton, Fade } from '@mui/material';
//Types
// import type { TableContactField } from 'redux/features/contactsSlice/types/TableContactField';
import { ContactBusinessTableContext } from 'features/Views/MarketingView/context/ContactBusinessTableContext';

// type Props = {
//   data: TableContactField;
// };

const Column = ({ data }) => {
  const { handleColumnClick } = useContext(ContactBusinessTableContext);
  // const pagination = useSelector(selectPagination);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => setIsHovered(true);

  const handleLeave = () => setIsHovered(false);

  return (
    <TableCell
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      sx={{
        py: '10px',
        '&:hover': { cursor: 'pointer' },
        userSelect: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      onClick={() => handleColumnClick(data.title)}
    >
      <HeadCellTitle title={data.title} />
      <Fade in={isHovered}>
        <IconButton sx={{ padding: '2px', ml: '2px', mb: '2px' }}>
          {/* <ArrowIcon pagination={pagination} columnData={data} /> */}
        </IconButton>
      </Fade>
    </TableCell>
  );
};

export default Column;
