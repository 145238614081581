import { useContext } from 'react';

import { Box } from '@mui/material';
import { CreateDiffusion, Filters } from './components';
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';

const AdvancedFilter = () => {
  // const { fieldSelect, initialConditionSelect, valueField, addFilter } = useContext(
  //   ContactBusinessTableContext
  // );

  return (
    <Box sx={{ maxHeight: 'calc(100vh - 330px)', overflow: 'auto' }}>
      {/* Filtros */}
      <Filters></Filters>
      <CreateDiffusion></CreateDiffusion>
      {/* Crear difusion botones */}

    </Box>
  );
};

export default AdvancedFilter;
