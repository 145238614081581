import { Box, ListItem, useTheme } from "@mui/material";
import { Card, DonutChart, List, Title } from "@tremor/react";

const delivers = [
  {
    type: "Sin rebotes",
    sales: 33,
  },
  {
    type: "Repetidos/Excluidos",
    sales: 33,
  },
  {
    type: "Pendientes",
    sales: 11,
  },
  {
    type: "Con rebotes",
    sales: 23,
  }

];

const valueFormatter = (number) => `${number}%`;
const GrapichDestiny = () => {
  const theme = useTheme();
  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        height: "100%",
      }}
    >
      <Title>Entregas</Title>
      <Box className="p-4 flex items-center space-x-6">
        <DonutChart
          className="mt-6"
          data={delivers}
          category="sales"
          index="type"
          valueFormatter={valueFormatter}
          colors={["slate", "violet", "indigo", "rose"]}
        />
        <List className="w-2/3">
          {delivers.map((locationData) => (
            <ListItem key={locationData.type} className="space-x-2">
              <div className="flex items-center space-x-2 truncate">
                <span
                  style={{
                    width: "1em",
                    height: "1em",
                    backgroundColor:
                      locationData.type === "Sin rebotes"
                        ? "#64748b"
                        : locationData.type === "Repetidos/Excluidos"
                        ? "violet"
                        : locationData.type === "Pendientes"
                        ? "indigo"
                        : locationData.type === "Con rebotes"
                        ? "#f43f5e"
                        : "",
                  }}
                />
                <span className="truncate">{locationData.type}</span>
              </div>
              <span>{locationData.sales} %</span>
            </ListItem>
          ))}
        </List>
      </Box>
    </Card>
  );
};

export default GrapichDestiny;
