import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { ContactData } from './types/ContactData';
import { Pagination } from './types/Pagination'
import { ContactNotes } from './types/ContactNotes';
import { ContactFile } from './types/ContactFile';
import { UpdateField } from './types/UpdateField';

const fetchContactInfo = async (
  jwt: string,
  URL: string,
  userId: string
) => {
  const config = getRequestConfig.basic(jwt);

  const response = await axios.get(
    `${URL}/contacts/${userId}`,
    config
  );

  return response.data;
};

const fetchContacts = async (
  jwtToken: string,
  URL: string,
  data: Pagination
): Promise<ContactData> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const dataJson = JSON.stringify(data);

  const response = await axios.post<ContactData>(
    `${URL}/Search`,
    dataJson,
    config
  );

  return response.data;
};

const fetchFields = async (
  jwtToken: string,
  URL: string,
  filterBy: string,
  fetchFor: string
) => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get(
    `${URL}/ContactFields?filter=${filterBy}&for=${fetchFor}`,
    config
  );

  return response.data;
}

const fetchNewNote = async (
  jwtToken: string,
  URL: string,
  data: ContactNotes
) => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const dataJson = JSON.stringify(data);
  const response = await axios.post(
    `${URL}/notes`,
    dataJson,
    config
  );

  return response.data;
}

const fetchNotes = async (
  jwtToken: string,
  URL: string,
  contactId: string
) => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get(
    `${URL}/notes/${contactId}`,
    config
  );

  return response.data;
}

const fetchUpdateNote = async (
  jwtToken: string,
  URL: string,
  data: ContactNotes
) => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const dataJson = JSON.stringify(data);
  const response = await axios.put(
    `${URL}/notes`,
    dataJson,
    config
  );

  return response.data;
}

const fetchDeleteNote = async (
  jwtToken: string,
  URL: string,
  data: ContactNotes
) => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.delete(
    `${URL}/notes/${data.contactId}/${data.noteId}`,
    config
  );

  return response.data;
}

const fetchNewFile = async (
  jwtToken: string,
  URL: string,
  data: ContactFile
) => {
  const config = getRequestConfig.contentTypeFormData(jwtToken);
  const dataJson = JSON.stringify(data);
  const response = await axios.post(
    `${URL}/files`,
    dataJson,
    config
  );

  return response.data;
}

const fetchFiles = async (
  jwtToken: string,
  URL: string,
  contactId: string
) => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get(
    `${URL}/files/${contactId}`,
    config
  );

  return response.data;
}

const fetchDeleteFile = async (
  jwtToken: string,
  URL: string,
  fileId: string,
  contactId: string
) => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.delete(
    `${URL}/files/${contactId}/${fileId}`,
    config
  );

  return response.data;
}

const fetchUpdateField = async (
  jwtToken: string,
  URL: string,
  data: UpdateField
) => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const {
    contactId,
    field,
    value
  } = data;
  const dataJson = JSON.stringify({
    field,
    value
  });
  const response = await axios.patch(
    `${URL}/contacts/updateField/${contactId}`,
    dataJson,
    config
  );

  return response.data;
}

const contactInfoService = {
  fetchContactInfo,
  fetchContacts,
  fetchFields,
  fetchNewNote,
  fetchNotes,
  fetchUpdateNote,
  fetchDeleteNote,
  fetchNewFile,
  fetchFiles,
  fetchDeleteFile,
  fetchUpdateField,
};

export default contactInfoService;
