import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { MarketingContext } from "features/Views/MarketingView/context/MarketingContext";
import CloseIcon from "@mui/icons-material/Close";
import { style } from "../style";
import { utils, flowList, channelList } from "./utils/utils";
import { useTranslation } from "react-i18next";

const WhatsappXLSX = () => {
  const { setOpenPanelCreateDifussion } = useContext(MarketingContext);
  const closePanel = () => {
    setOpenPanelCreateDifussion("");
  };
  const { t } = useTranslation();
  return (
    <Box sx={style.container}>
      <Box sx={style.header}>
        <IconButton sx={{ postion: "absolute" }} onClick={closePanel}>
          <CloseIcon
            sx={{
              fontSize: 20,
              color: "text.primary",
            }}
          />
        </IconButton>
        <Box>
          <Typography sx={style.title}>
            {t("marketingView.createDiffusionView.whatsappXLSXEdition")}
          </Typography>
        </Box>
        <Box>
          {" "}
          <Button size="small" variant="contained">
            {t("global.save")}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: "35px" }}>
        <Box sx={style.item}>
          <Typography sx={style.label}>
            {t("marketingView.createDiffusionView.title")}
          </Typography>
          <Box width={"250px"}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              fullWidth
              sx={style.textfield}
              placeholder={t("marketingView.createDiffusionView.enterATitle")}

            ></TextField>
          </Box>
        </Box>

        <Box sx={style.item}>
          <Typography sx={style.label}>
            {t("marketingView.createDiffusionView.subscription")}
          </Typography>
          <Box width={"250px"}>
            {" "}
            <FormControl size="small" fullWidth>
              {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
              <Select fullWidth>
                {utils.map((value, i) => (
                  <MenuItem key={i} value={value.susName}>
                    {value.susName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={style.item}>
          <Typography sx={style.label}>
            {t("marketingView.createDiffusionView.channel")}
          </Typography>
          <Box width={"250px"}>
            {" "}
            <FormControl size="small" fullWidth>
              {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
              <Select fullWidth>
                {channelList.map((value, i) => (
                  <MenuItem key={i} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={style.item}>
          <Typography sx={style.label}>Flow</Typography>
          <Box width={"250px"}>
            {" "}
            <FormControl size="small" fullWidth>
              {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
              <Select fullWidth>
                {flowList.map((value, i) => (
                  <MenuItem key={i} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={style.item}>
          <Typography sx={style.label}>
            {t("marketingView.createDiffusionView.uploadXLSXFile")}
          </Typography>
          <Box sx={{ marginRight: "10px" }}>
            {" "}
            <input type="file" />
          </Box>{" "}
          <Button size="small" variant="contained">
            {t("marketingView.createDiffusionView.uploadXLSX")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default WhatsappXLSX;
