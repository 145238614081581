import { PlayCircle } from '@mui/icons-material';
import { Box, Button, CardMedia, IconButton, Typography } from '@mui/material'
// Markdown
import { Markdown } from 'functions/Markdown'
import { useState } from 'react';

interface TextProps {
  text: string;
  buttons: any[];
}

const Image = () => {
  const [imgSize, setImgSize] = useState<number>(15);
  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          position="relative"
          sx={{
            height: `${imgSize}rem`,
            width: `${imgSize}rem`,
          }}
        >
   
          <CardMedia
            component={"img"}
            // onClick={() => handleOpenModal(i)}
            sx={{
              cursor: "pointer",
              padding: "1px",
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "2px",
            }}
            // image={image?.url}
            // alt={image?.caption}
            width="100%"
            height="100%"
          />
        </Box>
      </Box>
      {/* {files.length <= 1 && ( */}
      <Typography
        variant="caption"
        sx={{
          fontSize: "13px",
          wordBreak: "break-word",
          textAlign: "center",
          display: "flex",
          justifyContent: "start",
          color: (theme) => theme.palette.text.primary,
        }}
        dangerouslySetInnerHTML={{ __html: "text" }}
      />
      {/* )} */}
    </Box>
  );
}

export default Image