import { useState } from 'react';
// Components/ui
import {
  CardMedia,
  Skeleton,
  Box,
} from '@mui/material';
//Types
import { IGif } from "@giphy/js-types";

interface ItemProps {
  item: IGif
}

const Item = ({
  item
}: ItemProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  const handleImageLoaded = () => {
    setLoading(false);
  }

  return (
    <Box
      sx={{
        height: '8rem',
        width: '100%',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          transform: 'scale(1.1)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      {
        loading && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              bgcolor: (theme) => theme.palette.background.default,
              position: 'absolute',
              zIndex: 2,
            }}
          >
            <Skeleton
              variant="rectangular"
              height="100%"
              width='100%'
              sx={{
                borderRadius: '0.5rem',
              }}
            />
          </Box>
        )
      }
      <CardMedia
        component="img"
        src={item.images.original.url}
        alt={item.title}
        loading="lazy"
        style={{
          position: 'absolute',
          zIndex: 1,
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        }}
        onLoad={handleImageLoaded}
      />
    </Box>
  )
}

export default Item;