// Provider
// import ContactBusinessTableContextProvider from './context/ContactBusinessTableContextProvider';
// Components/ui
import { Box } from '@mui/material';
import {
  AppBody,
} from './components';
import MessagesProvider from './context/MessagesProvider/MessagesProvider';
import ConversationsProvider from './context/ConversationsProvider/ConversationsProvider';

const MarketingView = () => {
  return (
    // <ContactBusinessTableContextProvider>
    <Box
      display="flex"
      width="100%"
      height={"100%"}
      flexDirection={"column"}
      className={"marketing-paddingmd"}
    >
      {/* <AppHeader /> */}
      <MessagesProvider>
        <ConversationsProvider>
          <AppBody />
        </ConversationsProvider>
      </MessagesProvider>
      {/* <ModalList /> */}
    </Box>
    // </ContactBusinessTableContextProvider>
  );
};

export default MarketingView;
