export const novedadesList = [{ name: "Novedades" }]
export const contactList = [{ name: "Demo lista", cant: 5 }]
export const flowList = [
    { name: "Flow 1" },
    { name: "Flow 2" },
    { name: "Flow 3" },
  ]
export const channelList = [{ name: "channel 1"}]

export const MessageList = [{
  id: "1",
  externalId: "123",
  spaceId: "1234",
  conversationId: "12345",
  /**ISO8601 timestamp */
  timestamp: Date,
  userId: "123456",
  channelInfo: {
    id: "1234567",
    name: "canal 1",
    description: "canal description",
    type: 4
  },
  /**
   * ejemplos
   * wa:5493584023801,
   * email:info@trii.app,
   * Fb:11111111111111111,
   * Ig:22222222222222222,
   * sms:3584023801
   * */
  from: "lautaro",
  to: "lautaroDev",
  remoteContactData: {
    address: "string",
    name: "string",
    imageUrl: "string",
  },
  direction: {
    IN: 1,
    OUT: 2
  },
  // ack: MessageAck,
  forwarded: false,
  remoteDeleted: false,
  // type: MessageType,
  header: "string",
  footer: "string",
  mentions: [],
  context: "string",
  // text: MessageText,
  // contacts: MessageContact[],
  // audio: MessageAudio,
  images: [],
  // videos: MessageVideo[],
  // cdr: MessageCdr,k
  // locations: MessageLocation[],
  // documents: MessageDocument[],
  // stickers: MessageSticker[],
  // info: MessageInfo,
  // form: MessageForm,
  // ticket: MessageTicket,
  // email: MessageEmail,
  buttons: [],
  // poll: MessagePoll,
  // reactions: MessageReaction[],
  // messageReference: MessageReference,
  isLoaded: false,
  deleted: false,
  updatedAt: Date,
  // updatedBy: UserInfo,
}]