import { useContext } from 'react';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Components/ui
import { TableCell, Checkbox, TableRow, styled } from '@mui/material';
import { Cells } from './components';
// Types
// import type { IContactWithCheckbox } from 'redux/features/contactsSlice/types/IContactWithCheckBox';
// import type { IBusinessWithCheckbox } from 'redux/features/contactsSlice/types/IBusinessWithCheckBox';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  height: 35,
}));

// interface Props {
//   contact: IContactWithCheckbox | IBusinessWithCheckbox;
// }

const Contact = () => {
  // const { handleItemCheck } = useContext(ContactBusinessTableContext);

  return (
    <StyledTableRow>
      <TableCell sx={{ padding: '0 16px' }}>
        <Checkbox
          // checked={contact.checked}
          // onChange={() => handleItemCheck(contact.id)}
          sx={{ marginRight: 1 }}
        />
      </TableCell>
      <Cells  />
      <TableCell sx={{ padding: '0 16px' }} />
    </StyledTableRow>
  );
};

export default Contact;
