import { Box, Typography, useTheme } from "@mui/material";
import { style } from "../../../style";
import { useTranslation } from "react-i18next";
// Images
import bgLight from "../assets/images/bg-light.png";
import bgDark from "../assets/images/bg-dark.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Messages from "./components/Messages";
import { useEffect, useState } from "react";
import { Message } from "features/Views/MarketingView/context/MessagesProvider/types/Message";
import { MessageList } from "../../utils/utils";
import MessagesTest from "./components/MessagesTest";
const Preview = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const image = theme.palette.mode === "light" ? bgLight : bgDark;
  const [messages, setMessages] = useState<Message[]>([]);

  return (
    <Box sx={style.previewContainer}>
      <Typography>
        {t("marketingView.createDiffusionView.preview")} WhatsApp
      </Typography>
      <Box
        sx={{
          backgroundImage: `url(${image})`,
          width: "100%",
          height: "500px",
          backgroundSize: "100%",
          backgroundColor: theme.palette.mode == "dark" ? "" : "#F0EBE3",
          padding: "20px",
          border: "1px solid lightgray",
        }}
      >
        {/* <Box sx={{ background: "white", width: "fit-content", border: "1px solid lightgray" }}>
          <Box sx={{display: "flex", padding: "10px"}}>
            <WhatsAppIcon /> <Typography variant="body2">Mensaje</Typography>
          </Box>
        </Box> */}
        {/* {messages &&
          messages.length > 0 &&
          messages?.map((message, i) =>
            message.messages.map((message) => (
              <>
                <Messages key={message.id} message={message} />
              </>
            ))
            )} */}
            <MessagesTest />
      </Box>
    </Box>
  );
};

export default Preview;
