import { Box, Button, useTheme } from '@mui/material'
import { MarketingContext } from 'features/Views/MarketingView/context/MarketingContext';
import React, { useContext } from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

const CreateButtons = () => {
  const  {setOpenPanelCreateDifussion}  = useContext(MarketingContext);
//   const openPanel = () => {
//     setOpenPanelCreateDifussion(true);
// };
const OpenView = (title: string) => {
  setOpenPanelCreateDifussion(title);
    
} 
const theme = useTheme()

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}
        sx={{
          mb: "10px",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'

        }}
        // onClick={openPanel}
        onClick={() => OpenView("EditWhatsapp")}

        startIcon={<WhatsAppIcon />}
      >
        Whatsapp
      </Button>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}
        sx={{
          mb: "10px",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
        onClick={() => OpenView("EditWhatsappXLSX")}
        startIcon={<WhatsAppIcon />}

      >
        Whatsapp XLSX
      </Button>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}
        sx={{
          mb: "10px",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'

        }}
        onClick={() => OpenView("Email")}
        startIcon={<EmailIcon />}
      >
        Email
      </Button>
    </Box>
  );
}

export default CreateButtons