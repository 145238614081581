// components/ui
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Title = () => {
  const {t} = useTranslation()

  return (
<Box sx={{display: "flex", my: "20px"}}>
    <AddIcon sx={{
        color:"text.primary"
      }}/>
    <Typography
      color={'text.primary'}
      fontSize={15}
      fontWeight={500}
    >
      {t("marketingView.createDiffusionThrough")}:
    </Typography>
</Box>
  );
};

export default Title;
