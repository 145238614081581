// Types
import DocumentExtension from './types/DocumentExtension';
// Utils
import textDocuments from './utils/documentFiles';
// Components/ui
import { Box } from '@mui/material';

interface Props {
  documentType: DocumentExtension;
}

const DocumentIcon = ({ documentType }: Props) => {
  const textImageData = textDocuments.find((textDocument) => textDocument.name === documentType);

  if (textImageData) {
    return (
      <img
        src={textImageData.src}
        alt={'document type'}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1rem',
        fontWeight: 600,
        color: 'primary.contrastText',
        backgroundColor: 'primary.light',
      }}
    >
      {documentType?.toUpperCase()}
    </Box>
  );
};

export default DocumentIcon;
