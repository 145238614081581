import { useState, useEffect } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
// Selectors
import {
  getStickers,
  getFilterStickers,
  getNextFilterStickers,
  selectTenorStickers,
  selectTenorStickersFetchStatus,
  getNextStickers,
  selectNewTenorStickers,
} from 'redux/features/conversationSlice/conversationSlice';
// Translate
import { useTranslation, getI18n } from 'react-i18next';
// Components/ui
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  LinearProgress,
  Stack,
} from '@mui/material';
// Icons
import SearchIcon from '@mui/icons-material/Search';
// Types
import { IGif } from "@giphy/js-types";
// Components
import {
  Item,
} from './components';

const Stickers = () => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  const [value, setValue] = useState<string>('');
  const stickersTenor = useSelector(selectTenorStickers);
  const newStickersTenor = useSelector(selectNewTenorStickers);
  const tenorStickersFetchStatus = useSelector(selectTenorStickersFetchStatus);
  const loading = tenorStickersFetchStatus === 'loading'
  const [stickersList, setStickersList] = useState<IGif[]>(
    stickersTenor && stickersTenor.data ? stickersTenor.data : []
  );
  const [nextStiker, setNextStiker] = useState<number>(0);

  const handleChange = (value: string) => {
    setValue(value);
    if (value) {
      const { language } = getI18n();
      const data = {
        value,
        language,
      }
      dispatch(getFilterStickers(data));
    } else {
      dispatch(getStickers());
    }
  };

  const handleScroll = async (e) => {
    const bottom = (
      (e.target.scrollHeight - 200) - e.target.scrollTop
    ) <= e.target.clientHeight;
    if (bottom && !loading) {
      if (value) {
      const { language } = getI18n();
      const data = {
          value,
          next: nextStiker,
          language,
        }
        dispatch(getNextFilterStickers(data));
      } else {
        await dispatch(getNextStickers(nextStiker));
      }
    }
  };

  useEffect(() => {
    if (stickersTenor && stickersTenor.data) {
      setStickersList(stickersTenor.data);
      setNextStiker(stickersTenor.pagination.count);
    }
  }, [stickersTenor]);

  useEffect(() => {
    if (newStickersTenor && newStickersTenor.data) {
      setStickersList([...stickersList, ...newStickersTenor.data]);
      setNextStiker(newStickersTenor.pagination.count);
    }
  }, [newStickersTenor]);

  return (
    <Box
      sx={{
        display: 'flex',
        maxHeight: 'inherit',
        height: '100%',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box m={1}>
        <TextField
          id="input-with-icon-textfield"
          value={value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: '18px' }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          fullWidth
          placeholder={t('inputSearch.stickerSearch')}
        onChange={(e) => handleChange(e.target.value)}
        />
      </Box>
      {loading && (
        <Stack
          sx={{
            width: '100%',
            marginBottom: '.2rem',
            position: 'absolute',
          }}
        >
          <LinearProgress
            color="inherit"
            sx={{
              height: '0.2rem',
              color: (theme) => theme.palette.primary.main,
            }}
          />
        </Stack>
      )}
      <Box
        sx={{
          maxHeight: 'inherit',
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
        onScroll={(e) => handleScroll(e)}
      >
        <Box
          sx={{
            maxHeight: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gridGap: '1rem',
            placeItems: 'center',
          }}
        >
          {stickersList.map((item) => (
            <Item key={item.slug} item={item} />
          )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Stickers;
