import { Box } from "@mui/material";
import { EmailEdition, WhatsappEdition, WhatsappXLSX } from "./components";
import { useContext, useState } from "react";
import { MarketingContext } from "features/Views/MarketingView/context/MarketingContext";

const CreateDifussionPanel = () => {
  const [showWE, setShowWE] = useState(true);
  const [showWX, setShowWX] = useState(false);
  const [showEE, setShowEE] = useState(false);
  const { openPanelCreateDifussion } = useContext(MarketingContext);


  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height={"100%"}
      sx={{
        //@ts-ignore
        backgroundColor: (theme: Theme) =>
          theme.palette.background.dropdownMenu,
      }}
      boxShadow={"0px 1px 2px rgba(0,0,0,0.2)"}
      borderRadius={"2px"}
      p={2}
      width={"100%"}
    >
      {openPanelCreateDifussion == "EditWhatsapp" && (
        <>
          <WhatsappEdition></WhatsappEdition>
        </>
      )}
      {openPanelCreateDifussion == "EditWhatsappXLSX" && (
        <>
          <WhatsappXLSX></WhatsappXLSX>
        </>
      )}
      {openPanelCreateDifussion == "Email" && (
        <>
          <EmailEdition></EmailEdition>
        </>
      )}
    </Box>
  );
};

export default CreateDifussionPanel;
