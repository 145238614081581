export const DesuscriptionList = [
  {
    channel: "Contenidos irrelevantes",
    cant: 0,
  },
  {
    channel: "Demasiada frecuencia",
    cant: 0,
  },
  {
    channel: "Nunca me suscribí",
    cant: 0,
  },
  {
    channel: "Ya me desuscribí",
    cant: 0,
  },
  {
    channel: "Lo considero SPAM",
    cant: 0,
  },
  {
    channel: "Otros",
    cant: 0,
  },
];