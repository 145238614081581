import { Box, Button } from '@mui/material'
// Markdown
import { Markdown } from 'functions/Markdown'

interface TextProps {
  text: string;
  buttons: any[];
}

const Text = () => {
  const markdown = Markdown("texto texto texto", false)
  const newText = markdown.replace(/\n/g, "<br />")

  return (
    <Box sx={{ fontSize: "13px", wordBreak: "break-word", color: (theme) => theme.palette.text.primary }}>
      <Box
        dangerouslySetInnerHTML={{ __html: newText }}
      />
    
    </Box>
  )
}

export default Text