export const messageNumber =  [
    {
        number: 1
    },
    {
        number: 2
    },
    {
        number: 3
    },
    {
        number: 4
    },
    {
        number: 5
    },
]

export const formatList = [
    {
        type: "ninguno"
    },
    {
        type: "Texto"
    },
    {
        type: "Imagen"
    },
    {
        type: "Video"
    },
    {
        type: "Archivo"
    },
]