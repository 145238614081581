import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slide,
  TextField,
  TextareaAutosize,
  styled,
} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { novedadesList } from "../../utils/utils";
import { style } from "../../../style";
import { useTranslation } from "react-i18next";
import { formatList, messageNumber } from "./utils/utils";
import { AttachFile, Gif, Mood, Photo, PhotoFilter, VideoCameraBack, WhatsApp } from "@mui/icons-material";
import { ModalPersField } from "./components";
import { MarketingContext } from "features/Views/MarketingView/context/MarketingContext";
import Emojis from "components/Emojis";
import Stickers from "./components/Stickers/Stickers";
import { messagesContext } from "features/Views/MarketingView/context/MessagesProvider/MessagesProvider";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, px: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MessageTabs() {
  const [value, setValue] = React.useState(0);
  const { handleFileUpload, handleDocumentUpload } = React.useContext(messagesContext);
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [showEmoji, setShowEmoji] = React.useState<boolean>(true);
  const [showGif, setShowGif] = React.useState<boolean>(false);
  const [showActions, setShowActions] = React.useState<boolean>(false);
  const [showStickers, setShowStickers] = React.useState<boolean>(false);
  const [newEmoji, setNewEmoji] = React.useState<string>('');

  const { t } = useTranslation();
  const handleShowGif = () => {
    setShowGif(true);
    setShowEmoji(false);
    setShowStickers(false);
  };

  const handleShowStickers = () => {
    setShowStickers(true);
    setShowEmoji(false);
    setShowGif(false);
  };

  const handleEmojiSelect = (emoji) => {
    setNewEmoji(emoji.native);
  };

  const handleShowActions = () => {
    if (!showEmoji && !showActions) {
      setShowActions(true);
    } else if (!showEmoji && showActions) {
      setShowEmoji(true);
      setShowGif(false);
      setShowStickers(false);
    } else if (showEmoji && !showActions) {
      setShowActions(true);
    }
  };
  const handleImageIconClick = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.multiple = true;
    inputElement.addEventListener('change', handleFileUpload.bind(this));
    inputElement.click();
  };
  const handleVideoIconClick = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = ' video/*';
    inputElement.multiple = true;
    inputElement.addEventListener('change', handleFileUpload.bind(this));
    inputElement.click();
  };
  const handleTextFileIconClick = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;
    inputElement.addEventListener('change', handleDocumentUpload.bind(this));
    inputElement.click();
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? "white" : "gray"};
    background: ${theme.palette.mode === "dark" ? grey[900] : "inherit"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : "#D3D3D3"};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };
    resize: none;
    user-select: none;
  `
  );
  const {setOpenModalPersField, setSelectedFormat, selectedFormat} = React.useContext(MarketingContext)
  const handleOpenModalPersField = () => {
    setOpenModalPersField(true)
  }

  const handleGroupChange = (e: SelectChangeEvent<string>) => {
    setSelectedFormat(e.target.value);
  };

  return (
    <Box sx={{ width: "50%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab label= {t("marketingView.createDiffusionView.templateMessage")} {...a11yProps(0)} />
          <Tab label= {t("marketingView.createDiffusionView.manualMessage")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box width={"100%"}>
          {" "}
          <FormControl size="small" fullWidth>
            {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
            <Select>
              {novedadesList.map((value, i) => (
                <MenuItem key={i} value={value.name}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </CustomTabPanel>
      {/* Tab Mensaje manual */}
      <CustomTabPanel value={value} index={1}>
        {/* Numero de mensaje */}
        <Box sx={style.item}>
          <Typography sx={style.textRedact}>
            {t("marketingView.createDiffusionView.messageNumber")}
          </Typography>
          <Box width={"250px"}>
            {" "}
            <FormControl size="small" fullWidth>
              {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
              <Select fullWidth>
                {messageNumber.map((value, i) => (
                  <MenuItem key={i} value={value.number}>
                    {value.number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* Select Formato */}
        <Box sx={style.item}>
          <Typography sx={style.textRedact}>
            {t("marketingView.createDiffusionView.format")}
          </Typography>
          <Box width={"250px"}>
            {" "}
            <FormControl size="small" fullWidth>
              {/* <InputLabel>{t("settingsView.type")}</InputLabel> */}
              <Select fullWidth onChange={handleGroupChange} value={selectedFormat}>
                {formatList.map((value, i) => (
                  <MenuItem key={i} value={value.type}>
                    {value.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/* Tipo de formato */}
        {/* <Box sx={style.item}>
          <Typography sx={style.textRedact}>
            {t("marketingView.createDiffusionView.format")}
          </Typography>
          <Box width={"250px"}>
            {" "}
            <FormControl size="small" fullWidth>
              <Select fullWidth>
                {formatList.map((value, i) => (
                  <MenuItem key={i} value={value.type}>
                    {value.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box> */}
        {/* input texto para vista previa */}
        <Box sx={style.item}>
          {selectedFormat === "Texto" ? (
            <>
              <Typography sx={style.textRedact}>
                {t("marketingView.createDiffusionView.text")}
              </Typography>
            </>
          ) : selectedFormat === "Imagen" ? (
            <>
              <Typography sx={style.textRedact}>
                {t("marketingView.createDiffusionView.captionImage")}
              </Typography>
            </>
          ) : selectedFormat === "Video" ? (
            <>
              <Typography sx={style.textRedact}>
                {t("marketingView.createDiffusionView.captionVideo")}
              </Typography>
            </>
          ) : selectedFormat === "Archivo" ? (
            <>
              <Typography sx={style.textRedact}>
                {t("marketingView.createDiffusionView.selectFile")}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box sx={style.item}>
          <Box width={"100%"}>
            {/* <TextField
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              placeholder="Escribe un mensaje..."
              size="small"
              fullWidth
              sx={style.textfield}
            ></TextField> */}
            {selectedFormat !== "ninguno" && (
              <Textarea
                maxRows={5}
                minRows={5}
                sx={{ outline: "none" }}
                aria-label="maximum height"
                // placeholder={selectedFormat === "Texto" ? ""}            
              ></Textarea>
            )}
          </Box>
          <Box display="flex" flexDirection={"column"} marginLeft={"20px"}>
          {
            showActions && (
              <Box display="flex" width="100%" maxHeight="20rem">
                <Slide
                  direction="up"
                  in={showActions}
                  // container={containerRef.current}
                >
                  <Box height="100%" width="100%" maxHeight="inherit">
                    {showEmoji && (
                      <Emojis handleEmojiSelect={handleEmojiSelect} />
                    )}
                    {showGif && <Gif />}
                    {showStickers && <Stickers />}
                  </Box>
                </Slide>
              </Box>
            )
          }
            {selectedFormat === "Texto" ? (
              <>
                <IconButton sx={{ color: "black" }} onClick={() => handleShowActions()}>
                  <Mood />
                </IconButton>
                <IconButton sx={{ color: "black" }}  onClick={() => handleShowGif()}>
                  <Gif />
                </IconButton>
                <IconButton sx={{ color: "black" }} onClick={() => handleShowStickers()}>
                  <PhotoFilter />
                </IconButton>
              </>
            ) : selectedFormat === "Imagen" ? (
              <>
                <IconButton sx={{ color: "black" }} onClick={() => handleImageIconClick()}>
                  <Photo />
                </IconButton>
              </>
            ) : selectedFormat === "Video" ? (
              <>
                <IconButton sx={{ color: "black" }} onClick={() => handleVideoIconClick()}>
                  <VideoCameraBack />
                </IconButton>
              </>
            ) : selectedFormat === "Archivo" ? (
              <>
                <IconButton sx={{ color: "black" }} onClick={() => handleTextFileIconClick()}>
                  <AttachFile />
                </IconButton>
              </>
            ) : (
              <></>
            )}
          </Box>
          {/* <Box display="flex" width="100%" maxHeight="20rem">
                <Slide
                  direction="up"
                  in={showActions}
                  container={containerRef.current}
                >
                  <Box height="100%" width="100%" maxHeight="inherit">

                      <Emojis handleEmojiSelect={handleEmojiSelect} />

                  </Box>
                </Slide>
              </Box> */}
        </Box>
        <Box>
          <Button
            size="small"
            variant="contained"
            onClick={handleOpenModalPersField}
          >
            {t("marketingView.createDiffusionView.labelsForCustomFields")}
          </Button>
          <ModalPersField></ModalPersField>
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
