import React, { useContext, useEffect } from "react"
// Context
import { conversationsContext } from "features/Views/MarketingView/context/ConversationsProvider/ConversationsProvider"
import { messagesContext } from "features/Views/MarketingView/context/MessagesProvider/MessagesProvider"
// Translations
import { useTranslation } from "react-i18next"
// Components/ui
import { Box } from "@mui/material"
// Components
import {
  Attachments,
  DestinationBox,
  FromBox,
  Subject,
} from "./components"
import PlaygroundNodes from 'components/EmailEditor/nodes/PlaygroundNodes';
import PlaygroundEditorTheme from 'components/EmailEditor/themes/PlaygroundEditorTheme';
// Lexical
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { TableContext } from "components/EmailEditor/plugins/TablePlugin"
import { SharedAutocompleteContext } from "components/EmailEditor/context/SharedAutocompleteContext"
import { IContactAddress } from "@trii/types/dist/Contacts"
import EmailEditor from "components/EmailEditor"

interface BodyProps {
  openModal: boolean
}

const Body = ({
  openModal,
}: BodyProps) => {
  const {
    body,
    editorState,
    from,
    to,
    isCc,
    cc,
    isBcc,
    bcc,
    subject,
    setTo,
    setIsCc,
    setCc,
    setIsBcc,
    setBcc,
    handleUploadFile,
    setEditorState,
    setFooterSize,
    sendEmail,
    getContactFields,
    getVariableInfo,
  } = useContext(messagesContext)
  const { conversationSelected } = useContext(conversationsContext)

  const { t } = useTranslation()

  const handleChangeEmailTo = (
    setState: React.Dispatch<React.SetStateAction<IContactAddress[]>>
  ) => (
    event: React.ChangeEvent<{}>, newValue: IContactAddress[]
  ) => {
      setState(newValue)
    }

  const initialConfig = {
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          height: "100%",
          width: "100%",
          
        }}
      >
        <FromBox />
        {/* <DestinationBox
          title={t('marketingView.message.email.to')}
          isCc={isCc}
          setIsCc={setIsCc}
          isBcc={isBcc}
          setIsBcc={setIsBcc}
          selectedList={to}
          handleChangeEmailTo={handleChangeEmailTo(setTo)}
          openModal={openModal}
        />
        {
          isCc && (
            <DestinationBox
              title={t('marketingView.message.email.cc')}
              isCc={isCc}
              setIsCc={setIsCc}
              isBcc={isBcc}
              setIsBcc={setIsBcc}
              selectedList={cc}
              handleChangeEmailTo={handleChangeEmailTo(setCc)}
              openModal={openModal}
            />
          )
        }
        {
          isBcc && (
            <DestinationBox
              title={t('marketingView.message.email.bcc')}
              isCc={isCc}
              setIsCc={setIsCc}
              isBcc={isBcc}
              setIsBcc={setIsBcc}
              selectedList={bcc}
              handleChangeEmailTo={handleChangeEmailTo(setBcc)}
              openModal={openModal}
            />
          )
        } */}
        <Subject />
        {/* <Attachments /> */}
        <Box
          sx={{
            width: "100%",
            height: `calc(100% - ${(isCc && isBcc ? '17rem' : (isCc || isBcc) ? '14rem' : '11rem')})`,
            position: "relative",
            borderTop: (theme) => `2px solid ${theme.palette.divider}`,
          }}
        >
          <TableContext>
            <SharedAutocompleteContext>
              <EmailEditor
                openModal={openModal}
                setValue={() => { }}
                handleUploadFile={handleUploadFile}
                body={body}
                editorState={editorState}
                setEditorState={setEditorState}
                from={from}
                to={to}
                subject={subject}
                setFooterSize={setFooterSize}
                conversationSelected={conversationSelected}
                sendEmail={sendEmail}
                getContactFields={getContactFields}
                getVariableInfo={getVariableInfo}
              />
            </SharedAutocompleteContext>
          </TableContext>
        </Box>
      </Box>
    </LexicalComposer>
  )
}

export default Body