import React from "react"
import {
  Card,
  List,
  ListItem,
  Icon,
  Text,
  Bold,
  Flex,
  Title,
} from "@tremor/react";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';

// const MockList = [];
const MockList = [
  {
    icon: "img/channels/19.png",
    channel: "Trii",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.soluc",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
  {
    icon: "img/channels/19.png",
    channel: "Trii.test",
    cant: 0
  },
];

const SimpleListCard = (item) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        height: "100%"
      }}
    >
      <Title style={{ color: theme.palette.text.primary }}>{item.title}</Title>
      <List className="mt-4  overflow-y-auto pr-2  min-h-382 position-relative" style={{minHeight: "250px", maxHeight: "250px"}}>
        {MockList.length > 0 ? (
          <>
            {MockList.map((transaction) => (
              <ListItem key={transaction.channel} className=" py-1">
                <Flex className="justify-start  truncate">
                  {/* <img src={`${__webpack_public_path__}${transaction.icon}`} alt="" /> */}
                  {/* <Icon icon={FacebookIcon} size="sm" /> */}
                  <div className="truncate">
                    <Text className="truncate">
                      <Bold>{transaction.channel}</Bold>
                    </Text>
                  </div>
                </Flex>
                <Text>{transaction.cant}</Text>
              </ListItem>
            ))}
          </>
        ) : (
          <>
            {item.id === "channels" ? (
              <div className="center-position-absolute" style={{ color: theme.palette.text.primary }}>
                <Text>{t("dashboard.NoChannelsList")}</Text>
              </div>
            ) : (
              <div className="center-position-absolute" style={{ color: theme.palette.text.primary }}>
                <Text>{t("dashboard.NoTagsList")}</Text>
              </div>
            )}
          </>
        )}
      </List>
    </Card>
  );
};

export default SimpleListCard