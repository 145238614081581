import { Box } from '@mui/material'
import React from 'react'
import Title from './Title'
import FilterButtons from './FilterButtons'

const Filters = () => {
  return (
    <Box>
      <Title></Title>
      <FilterButtons></FilterButtons>
    </Box>
  );
}

export default Filters