import { ChangeEvent, useEffect, useState } from 'react';

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';

import { CAN_USE_DOM } from 'shared/src/canUseDOM';

import { useSettings } from './context/SettingsContext';
import TableCellNodes from './nodes/TableCellNodes';
import ActionsPlugin from './plugins/ActionsPlugin';
import AutocompletePlugin from './plugins/AutocompletePlugin';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import QuickResponse from './plugins/QuickResponse';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import InlineImagePlugin from './plugins/InlineImagePlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
import MentionsPlugin from './plugins/MentionsPlugin';
import PageBreakPlugin from './plugins/PageBreakPlugin';
import PollPlugin from './plugins/PollPlugin';
import SpeechToTextPlugin from './plugins/SpeechToTextPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import TableOfContentsPlugin from './plugins/TableOfContentsPlugin';
import { TablePlugin as NewTablePlugin } from './plugins/TablePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import TwitterPlugin from './plugins/TwitterPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
import './index.css';
// Redux
import { useSelector } from 'react-redux';
// Context
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
// Slice
import { selectUploadMediaStatus } from 'redux/features/messagesSlice/messagesSlice';
// Theme
import { useTheme } from '@mui/material';
import { CLEAR_EDITOR_COMMAND, $getRoot, $insertNodes } from 'lexical';
import { IConversation } from '@trii/types/dist/Conversations';
import { FieldsData } from 'redux/features/contactInfoSlice/types/FieldsData';
import { Variable } from 'redux/features/messagesSlice/types/Variable';
import { IContactAddress } from '@trii/types/dist/Contacts';

interface EditorProps {
  openModal: boolean;
  hideActions?: boolean;
  body?: string;
  editorState?: string;
  from?: string;
  to?: IContactAddress[];
  subject?: string;
  conversationSelected?: IConversation;
  hideMentions?: boolean;
  setValue: (value: string) => void;
  handleUploadFile?: (e: ChangeEvent<HTMLInputElement>) => void;
  setEditorState?: (editorState: string) => void;
  setFooterSize?: (size: string) => void;
  sendEmail?: (conversationId: string, htmlString: string) => void;
  getContactFields?: (data: FieldsData) => void;
  getVariableInfo?: (data: Variable) => void;
}

export default function EmailEditor({
  openModal,
  hideActions = false,
  body,
  editorState,
  from,
  to,
  subject,
  conversationSelected,
  hideMentions = false,
  setValue,
  handleUploadFile,
  setEditorState,
  setFooterSize,
  sendEmail,
  getContactFields,
  getVariableInfo,
}: EditorProps): JSX.Element {
  const theme = useTheme();
  const [editor] = useLexicalComposerContext();
  const {
    settings: {
      isAutocomplete,
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      showTableOfContents,
      tableCellMerge,
      tableCellBackgroundColor,
    },
  } = useSettings();
  const isEditable = useLexicalEditable();
  const text = '';
  const placeholder = (
    <Placeholder
      className={`Placeholder__root ${!openModal ? 'small' : ''}`}
    >
      {text}
    </Placeholder>
  );
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);
  const filesFetchStatus = useSelector(selectUploadMediaStatus);
  const isLoading = filesFetchStatus === 'loading';

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const cellEditorConfig = {
    namespace: `${openModal ? 'ModalPlayground' : 'Playground'}`,
    nodes: [...TableCellNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  const handleChange = () => {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      setValue(htmlString);
    });
  }

  useEffect(() => {
    if (editorState) {
      const initialEditorState = editor.parseEditorState(editorState);
      editor.setEditorState(initialEditorState);
    } else {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }

    return () => {
      if (editor) {
        setEditorState(JSON.stringify(editor.getEditorState()));
      }
    }
  }, [])

  useEffect(() => {
    if (editorState) {
      const initialEditorState = editor.parseEditorState(editorState);
      editor.setEditorState(initialEditorState);
    } else {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }
  }, [editorState, conversationSelected, setFooterSize])

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  useEffect(() => {
    if (body && editor) {
      editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(body, 'text/html');

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);

        // Select the root
        $getRoot().select();

        // Insert them at a selection.
        $insertNodes(nodes);
      });
    }
  }, [editor])

  return (
    <>
      {openModal && <ToolbarPlugin />}
      <ToolbarPlugin />
      <div
        onKeyUp={handleChange}
        className={`editor-container ${!openModal ? 'small' : ''}`}>
        {isMaxLength && <MaxLengthPlugin maxLength={30} />}
        <DragDropPaste />
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <QuickResponse />
        <EmojiPickerPlugin />
        <AutoEmbedPlugin />
        {
          !hideMentions && (
            <MentionsPlugin
              getContactFields={getContactFields}
              getVariableInfo={getVariableInfo}
              conversationSelected={conversationSelected}
            />
          )
        }
        <EmojisPlugin />
        <HashtagPlugin />
        <KeywordsPlugin />
        <SpeechToTextPlugin />
        <AutoLinkPlugin />
        <RichTextPlugin
          contentEditable={
            <div className="editor-scroller">
              <div className={`editor ${theme.palette.mode === 'dark' ? 'dark' : ''}`} ref={onRef}>
                <ContentEditable />
              </div>
            </div>
          }
          placeholder={placeholder}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <CodeHighlightPlugin />
        <ListPlugin />
        <CheckListPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <TablePlugin
          hasCellMerge={tableCellMerge}
          hasCellBackgroundColor={tableCellBackgroundColor}
        />
        <TableCellResizer />
        <NewTablePlugin cellEditorConfig={cellEditorConfig}>
          <AutoFocusPlugin />
          <RichTextPlugin
            contentEditable={
              <ContentEditable className="TableNode__contentEditable" />
            }
            placeholder={null}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {
            !hideMentions && (
              <MentionsPlugin
                getContactFields={getContactFields}
                getVariableInfo={getVariableInfo}
                conversationSelected={conversationSelected}
              />
            )
          }
          <ImagesPlugin captionsEnabled={false} />
          <LinkPlugin />
          <LexicalClickableLinkPlugin />
          <FloatingTextFormatToolbarPlugin />
        </NewTablePlugin>
        <ImagesPlugin />
        <InlineImagePlugin />
        <LinkPlugin />
        <PollPlugin />
        <TwitterPlugin />
        <YouTubePlugin />
        {!isEditable && <LexicalClickableLinkPlugin />}
        <HorizontalRulePlugin />
        <TabFocusPlugin />
        <TabIndentationPlugin />
        <CollapsiblePlugin />
        <PageBreakPlugin />
        {floatingAnchorElem && !isSmallWidthViewport && (
          <>
            <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
            <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
            <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
            <TableCellActionMenuPlugin
              anchorElem={floatingAnchorElem}
              cellMerge={true}
            />
            <FloatingTextFormatToolbarPlugin
              anchorElem={floatingAnchorElem}
            />
          </>
        )}
        {(isCharLimit || isCharLimitUtf8) && (
          <CharacterLimitPlugin
            charset={isCharLimit ? 'UTF-16' : 'UTF-8'}
            maxLength={5}
          />
        )}
        {isAutocomplete && <AutocompletePlugin />}
        <div>{showTableOfContents && <TableOfContentsPlugin />}</div>
        {
          !hideActions && (
            <ActionsPlugin
              handleUploadFile={handleUploadFile}
              openModal={openModal}
              conversationId={conversationSelected?.id}
              canSend={!isLoading && (
                from != '' && to.length > 0 && subject != '' && body != ''
              )}
              sendEmail={sendEmail}
            />
            )
          }
          <ActionsPlugin
            handleUploadFile={handleUploadFile}
            openModal={openModal}
            conversationId={conversationSelected?.id}
            canSend={!isLoading && (
              from != '' && to.length > 0 && subject != '' && body != ''
            )}
            sendEmail={sendEmail}
          />
      </div>
      <HistoryPlugin />
    </>
  );
}
