import axios from 'axios';
import getRequestConfig from 'redux/functions/getRequestConfig';
// Types
import { IMessage } from '@trii/types/dist/Common/Messages';

const fetchMessages = async (
  jwt: string,
  URL: string,
  conversationId: string,
  pos: string,
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/Messages?conversationId=${conversationId}&limit=20&pos=${pos}`,
    requestConfig
  );
  return response.data;
};

const sendMessages = async (
  jwt: string,
  URL: string,
  message: IMessage
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const messageJson = JSON.stringify(message);
  const response = await axios.post(
    `${URL}/Messages`,
    messageJson,
    requestConfig
  );
  return response.data;
};

const uploadMedia = async (
  jwt: string,
  URL: string,
  file: FormData,
  name: string,
  id: string
) => {
  const requestConfig = getRequestConfig.contentTypeFormData(jwt);
  const response = await axios.post(
    `${URL}/upload`,
    file,
    requestConfig
  );
  const data = {
    name,
    url: response.data,
    id,
  }
  return data;
};

const fetchVariables = async (
  jwt: string,
  URL: string,
  variable: string,
  contactId: string,
  conversationId: string,
) => {
  const requestConfig = getRequestConfig.basic(jwt);
  const response = await axios.get(
    `${URL}/Vars?q=${variable}&contactId=${contactId}&conversationId=${conversationId}`,
    requestConfig
  );
  return response.data;
}


const messagesService = {
  fetchMessages,
  sendMessages,
  uploadMedia,
  fetchVariables,
};

export default messagesService;
