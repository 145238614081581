import { useContext } from 'react';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useSelector } from 'react-redux';
// import { selectContactFields } from 'redux/features/contactsSlice/contactsSlice';
// Components/ui
import { TableCell, Button } from '@mui/material';
import { ContactBusinessTableContext } from 'features/Views/MarketingView/context/ContactBusinessTableContext';
import CheckIcon from '@mui/icons-material/Check';
// Types
// import type { IContactWithCheckbox } from 'redux/features/contactsSlice/types/IContactWithCheckBox';
// import type { IBusinessWithCheckbox } from 'redux/features/contactsSlice/types/IBusinessWithCheckBox';

// type Props = {
//   contact: IContactWithCheckbox | IBusinessWithCheckbox;
// };
const Cells = () => {
  const columnDialog = [
    {
      title: "Estado",
      visible: true,
      value: <CheckIcon color="success"/>
    },
    {
      title: "Nombre",
      visible: true,
      value: "Titulo de campaña"
  
    },
    {
      title: "Destinatarios",
      visible: true,
      value: "0"
  
    },
    {
      title: "Entregados",
      visible: true,
      value: "0"
  
    },
    {
      title: "Aperturas(%)",
      visible: true,
      value: "0%"

    },
    {
      title: "Clicks",
      visible: true,
      value: "0"

    },
    {
      title: "Fecha de envío",
      visible: true,
      value: "05/05/2023"

    },
    {
      title: "Acciones",
      visible: true,
      value: <Button variant='contained' size="small">Editar</Button>

    },
  ]

  // const columns = useSelector(selectContactFields);
  return (
    <>
      {columnDialog.map(
        (column, i) =>
          column.visible && (
            <TableCell key={i} sx={{ padding: '0 24px' }}>
              <span
                // onClick={() => handleContactClick(contact.id)}
                style={{ userSelect: 'none' }}
              >
                {column.value}
              </span>
            </TableCell>
          )
      )}
           <TableCell sx={{ padding: '0 24px' }}>

            </TableCell>
    </>
  );
};

export default Cells;
