import * as React from 'react';
import { Box, DialogTitle, IconButton, Modal, Typography } from "@mui/material";
import { Card, List, ListItem, Text, Bold, Flex } from "@tremor/react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from 'react-i18next';
import { DashboardContext } from '../../context/DashboardContext';
export default function RegisterModal() {
  // React.useEffect(() => {
  // }, [openModalRegister])
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false);
  const {
    modalRegisterOpen,
    modalRegisterList,
    modalRegisterTitle,
    setModalRegisterOpen,
    setModalRegisterList,
    setModalRegisterTitle
  } = React.useContext(DashboardContext);
  const openModal = modalRegisterOpen;
  const registerList = modalRegisterList;

  const handleClose = () => {
    setModalRegisterOpen(false);
    setModalRegisterList([])
    setModalRegisterTitle('')
    setOpen(false);
  };

  React.useEffect(() => {
    openModal && setOpen(true);
  }, [openModal]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.default,
          height: 'max-content',
          width: '40vw',
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography
            color={theme => theme.palette.text.primary}
            variant={'h6'}
          >
            {t("dashboard.logs", { title: t(`${modalRegisterTitle}`) })}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon
              sx={{
                fontSize: "20px",
                color: theme => theme.palette.text.disabled,
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxHeight: '20vh',
            overflow: 'auto'
          }}
        >
          <List
            className="mt-4"
          >
            {registerList.map((e, index) => (
              <ListItem key={index}>
                <Typography
                  color={theme => theme.palette.text.primary}
                  fontWeight={'bold'}
                >
                  {e?.channel}
                </Typography>
                <Typography
                  color={theme => theme.palette.text.primary}
                  fontWeight={'bold'}
                >
                  {e?.cant}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Modal>
  );
}
